import { Link, useLocation } from "react-router-dom";
import { LdsSideNav, LdsLillyLogo } from "@elilillyco/ux-lds-react";

export function SideNavBar({ children }) {
  const location = useLocation();

  const SideNav =
    sessionStorage.getItem("role") === "spclty_hub_super_admin"
      ? [
          {
            text: "Home",
            link: { to: "/" },
          },
          {
            text: "Users",
            link: { to: "/admin/users" },
          },
          {
            text: "Specialties",
            link: { to: "/admin/specialties" },
          },
          {
            text: "BU",
            link: { to: "/admin/BU" },
          },
          {
            text: "Import",
            link: { to: "/superadmin/import" },
          },
        ]
      : [
          {
            text: "Home",
            link: { to: "/" },
          },
          {
            text: "Users",
            link: { to: "/admin/users" },
          },
          {
            text: "Specialties",
            link: { to: "/admin/specialties" },
          },
          {
            text: "BU",
            link: { to: "/admin/BU" },
          },
        ];

  // Props and children get passed onto your router link from the side nav. This will include things like className and
  // href/ to properties from the menu object.
  const getRouterLink = (props, children) => <Link {...props}>{children}</Link>;

  return (
    <LdsSideNav
      logo={<LdsLillyLogo size="sm" paddingOverrideClass="pd-0" />}
      currentRoute={location.pathname}
      routerLink={getRouterLink}
      menu={SideNav}
    >
      {children}
    </LdsSideNav>
  );
}
