import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAddedSpecialties,
  getNonAddedSpecialties,
  updateSpecialties,
} from "../api/specialties";

const pageSize = 10;

const initialState = {
  specialties: [],
  searchText: "",
  currentPage: 1,
  totalPages: 0,
  start: 0,
  end: pageSize,
  loading: true,
};

const reducers = {
  SET_SPECIALTIES(state, { payload }) {
    state.specialties = payload;
  },
  SET_CURRENT_PAGE(state, { payload }) {
    state.currentPage = payload;
    const start = (payload - 1) * pageSize;
    state.start = start;
    state.end = start + pageSize;
  },
  SET_TOTAL_PAGES(state, { payload }) {
    state.totalPages = payload;
  },
  SET_SEARCH_TEXT(state, { payload }) {
    state.searchText = payload;
  },
  FILTER_SPECIALTIES(state, { payload }) {
    const text = payload;
    let filteredSpecialtiesLength = 0;
    let specialtiesToFilter = JSON.parse(JSON.stringify(state.specialties));
    let filteredSpecialties = specialtiesToFilter.map((specialty) => {
      specialty.show =
        specialty.specialty.toLowerCase().includes(text.toLowerCase()) ||
        specialty.description.toLowerCase().includes(text.toLowerCase());
      if (specialty.show) {
        filteredSpecialtiesLength++;
      }
      return specialty;
    });
    specialtiesSlice.caseReducers.SET_SPECIALTIES(state, {
      payload: filteredSpecialties,
    });
    specialtiesSlice.caseReducers.SET_TOTAL_PAGES(state, {
      payload: Math.ceil(filteredSpecialtiesLength / pageSize),
    });
    specialtiesSlice.caseReducers.SET_CURRENT_PAGE(state, {
      payload: 1,
    });
  },
  RESET_DATA(state, action) {
    specialtiesSlice.caseReducers.SET_SPECIALTIES(state, {
      payload: [],
    });
    specialtiesSlice.caseReducers.SET_TOTAL_PAGES(state, {
      payload: 0,
    });
    specialtiesSlice.caseReducers.SET_CURRENT_PAGE(state, {
      payload: 1,
    });
    specialtiesSlice.caseReducers.SET_SEARCH_TEXT(state, {
      payload: "",
    });
  },
};

const FETCH_SPECIALTIES = createAsyncThunk(
  "specialties/FETCH_SPECIALTIES",
  async (isAddPage) => {
    const { data, status } = isAddPage
      ? await getNonAddedSpecialties("")
      : await getAddedSpecialties("");
    return { data, status };
  }
);

const UPDATE_SPECIALTY = createAsyncThunk(
  "specialties/UPDATE_SPECIALTY",
  async (obj) => {
    const { data, status } = await updateSpecialties(obj);
    return { data, status };
  }
);

const specialtiesSlice = createSlice({
  name: "specialties",
  initialState,
  reducers,
  extraReducers(builder) {
    // FETCH_SPECIALTIES
    builder.addCase(FETCH_SPECIALTIES.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(FETCH_SPECIALTIES.fulfilled, (state, { payload }) => {
      const specialties = JSON.parse(JSON.stringify(payload.data.data));

      specialties.forEach((element) => {
        element.show = true;
      });

      // sorting the data based on specialty code
      specialties.sort((aVal, bVal) =>
        aVal.specialty.toLowerCase() < bVal.specialty.toLowerCase() ||
        (typeof aVal !== typeof bVal && typeof aVal === "number")
          ? -1
          : 1
      );

      specialtiesSlice.caseReducers.SET_SPECIALTIES(state, {
        payload: specialties,
      });
      specialtiesSlice.caseReducers.SET_TOTAL_PAGES(state, {
        payload: Math.ceil(specialties.length / pageSize),
      });

      if (state.searchText) {
        specialtiesSlice.caseReducers.FILTER_SPECIALTIES(state, {
          payload: state.searchText,
        });
      }

      state.loading = false;
    });
    builder.addCase(FETCH_SPECIALTIES.rejected, (state, action) => {
      state.loading = false;
    });

    // UPDATE_SPECIALTY
    builder.addCase(UPDATE_SPECIALTY.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(UPDATE_SPECIALTY.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  SET_SPECIALTIES,
  SET_CURRENT_PAGE,
  SET_TOTAL_PAGES,
  SET_SEARCH_TEXT,
  FILTER_SPECIALTIES,
  RESET_DATA,
} = specialtiesSlice.actions;

export { FETCH_SPECIALTIES, UPDATE_SPECIALTY };

export default specialtiesSlice.reducer;
