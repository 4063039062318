import ctx from "../../plugins/axios";

/**
 * Get Specialties list.
 */
const getNonAddedSpecialties = async (searchKeyword) =>
  await ctx.get(
    `/specialty?lilly_specialty=false&search_keyword=${encodeURI(
      searchKeyword
    )}`
  );
export default getNonAddedSpecialties;
