import ReactQuill from "react-quill";
import "quill-mention";

import "react-quill/dist/quill.snow.css";

import React, { useCallback, useRef } from "react";

const CommentBox = ({ value, setValue, suggestions }) => {
  const commentRef = useRef(null);

  // avoiding media and script
  const formats = [
    "background",
    "bold",
    "color",
    "font",
    "code",
    "italic",
    "link",
    "size",
    "strike",
    "underline",
    "blockquote",
    "header",
    "indent",
    "list",
    "align",
    "direction",
    "code-block",
    "formula",
    "mention",
    // "image",
    // 'video',
    // "script",
  ];

  const modules = {
    toolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ],
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,

      mentionDenotationChars: ["@"],

      spaceAfterInsert: true,

      source: useCallback((searchTerm, renderList, mentionChar) => {
        const values = suggestions;
        if (searchTerm.length === 0) {
          const user = values.slice(0, 5);
          renderList(user, searchTerm);
        } else {
          const matches = [];
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < values.length; i++) {
            if (values[i].value.toLowerCase().match(searchTerm.toLowerCase())) {
              matches.push(values[i]);
              const user = matches.slice(0, 5);
              renderList(user, searchTerm);
            } else {
              // matches = [];
              renderList(matches.slice(0, 5), searchTerm);
            }
          }
        }
      }, []),
    },
  };

  return (
    <ReactQuill
      value={value}
      theme="snow"
      ref={commentRef}
      modules={modules}
      formats={formats}
      onChange={(content) => {
        setValue(content);
      }}
    />
  );
};

export default React.memo(CommentBox);
