import ctx from "../../plugins/axios";

/**
 * Gets the Product List by Brand Selection.
 *
 * @returns {Promise<*>}
 */
const getIndicationListByBrand = async ({
  brand,
  department,
  search_keyword,
  grpPrpsCd,
}) => {
  return await ctx.get(
    `/specialty_list/indication?brand=${brand}&department_field=${department}&search_keyword=${search_keyword}&grp_prps_cd=${grpPrpsCd}`
  );
};

export default getIndicationListByBrand;
