import { LdsLink } from "@elilillyco/ux-lds-react";

const NotFound = () => {
  return (
    <div className="wrapper section">
      <h1>Page not found</h1>
      <LdsLink href="/">Home Page</LdsLink>
    </div>
  );
};

export default NotFound;
