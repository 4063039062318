import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFilter,
  getApprovedSpecialties,
} from "../api/approvedSpecialties/index";
const initialState = {
  filterBrand: [],
  filterIndication: [],
  filterQuarter: [],
  filterDepartment: [],
  approvedSpecialties: [],
  loading: true,
};
const reducers = {
  SET_FILTER_LIST(state, filterData) {
    const { respData } = filterData;
    state.filterBrand = respData.brand;
    state.filterIndication = respData.indication.sort();
    state.filterQuarter = respData.list_quarter.sort();
    state.filterDepartment = respData.department_field.sort();
  },
  SET_APPROVED_SPECIALTIES(state, approvedSpecialties) {
    const { respData, page, totalPages } = approvedSpecialties;
    const Data = respData;
    state.approvedSpecialtiesPgNo = page;
    state.approvedSpecialtiestotalPg = totalPages;
    state.approvedSpecialties = Data;
  },
};
const FETCH_FILTER_LIST = createAsyncThunk(
  "approvedSpecialties/FETCH_FILTER_LIST",
  async () => {
    const { data, status } = await getFilter();
    return { data, status };
  }
);
const FETCH_APPROVED_SPECIALTIES = createAsyncThunk(
  "approvedSpecialties/FETCH_APPROVED_SPECIALTIES",
  async (obj) => {
    const { data, status } = await getApprovedSpecialties(obj);
    return { data, status };
  }
);
const approvedSpecialtiesSlice = createSlice({
  name: "approvedSpecialtiesScreen",
  initialState,
  reducers,
  extraReducers(builder) {
    builder.addCase(FETCH_FILTER_LIST.fulfilled, (state, { payload }) => {
      const { data: filterList, status } = payload;
      if (status === 200) {
        state.filterIndication = filterList.data.indication.sort() || [];
        state.filterBrand = filterList.data.brand || [];
        state.filterDepartment = filterList.data.department_field.sort() || [];
        state.filterQuarter = filterList.data.list_quarter.sort() || [];
      }
      state.loading = false;
    });
    builder.addCase(FETCH_FILTER_LIST.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(FETCH_FILTER_LIST.rejected, (state, action) => {});

    builder.addCase(
      FETCH_APPROVED_SPECIALTIES.fulfilled,
      (state, { payload }) => {
        const { data: respData, status } = payload;
        if (status === 200) {
          state.approvedSpecialties = respData.data;
        }
        state.loading = false;
      }
    );
    builder.addCase(FETCH_APPROVED_SPECIALTIES.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(FETCH_APPROVED_SPECIALTIES.rejected, (state, action) => {
      state.loading = false;
    });
  },
});
export const { SET_FILTER_LIST, SET_APPROVED_SPECIALTIES } =
  approvedSpecialtiesSlice.actions;
export { FETCH_FILTER_LIST, FETCH_APPROVED_SPECIALTIES };

export default approvedSpecialtiesSlice.reducer;
