import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  LdsButton,
  LdsCard,
  useLdsModal,
  useToastContext,
  LdsLink,
  LdsCheckbox,
  LdsIcon,
  LdsPagination,
} from "@elilillyco/ux-lds-react";

import EditBulkUserStatus from "./EditBulkUserStatus";
import EditUserStatus from "./EditUserStatus";
import EditUserDetailsModal from "./EditUserDetailsModal";
import infoToastConfig from "../shared/Toast";
import roleMapping from "../../data/roleMapping";

import "../../assets/scss/admin/usertable.scss";
import SortableHeader from "../shared/SortableHeader";

const pageSize = 10;

const UsersTable = ({ saveUserListForm, searchText, isProfile }) => {
  const { addToast } = useToastContext();

  const { userTableList, Brands, loading } = useSelector(({ admin }) => admin);

  const [userList, setuserList] = useState(userTableList);
  const [updateStatusObj, setupdateStatusObj] = useState({});
  const [bulkUserStatusVal, setbulkUserStatusVal] = useState("");
  const [userStatusVal, setuserStatusVal] = useState("");
  const [userDataObj, setuserDataObj] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(pageSize);

  const {
    isModalOpen: isEditBulkStatusOpen,
    setIsModalOpen: setisEditBulkStatusOpen,
  } = useLdsModal();
  const { isModalOpen: isEditStatusOpen, setIsModalOpen: setisEditStatusOpen } =
    useLdsModal();
  const {
    isModalOpen: isEditUserDetailsOpen,
    setIsModalOpen: setEditUserDetailsOpen,
  } = useLdsModal();

  const userTableHeader = isProfile ? [
    "First Name",
    "Last Name",
    "Email Id",
    "Role",
    "Brand",
    "Status",
  ] : [
    "First Name",
    "Last Name",
    "Email Id",
    "Role",
    "Brand",
    "Status",
    "Edit Status",
  ];

  useEffect(() => {
    let userListCopy = JSON.parse(JSON.stringify(userTableList));
    userListCopy = userListCopy.map((user) => {
      const status = user.active ? "Active" : "InActive";
      return { ...user, status };
    });
    filterUserList(userListCopy);
  }, [userTableList]);

  useEffect(() => {
    filterUserList(userList);
  }, [searchText]);

  const filterUserList = (userList) => {
    let draft = JSON.parse(JSON.stringify(userList));
    draft.forEach((item) => (item.isVisible = false));
    draft = draft.map((s) => {
      if (
        s.email_id.toLowerCase().includes(searchText.toLowerCase()) ||
        s.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
        s.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
        s.brand.find((o) => o.toLowerCase().includes(searchText.toLowerCase()))
      ) {
        return { ...s, isVisible: true };
      } else {
        return { ...s, isVisible: false };
      }
    });

    setuserList(draft);
    setTotalPages(
      Math.ceil(draft.filter((user) => user.isVisible).length / pageSize)
    );
    updateCurrentPage(1);
  };

  const roleNameMapping = (role) => {
    if (!role) return null;
    return roleMapping[role];
  };

  const convertArray = (val) => {
    if (val.length === Brands.length) {
      return "ALL";
    }
    return val.toString().replace(/,/g, ", ") || "";
  };

  const selcheckBoxClicked = (event, UUID) => {
    const tempData = JSON.parse(JSON.stringify(userList));

    for (let i = 0; i < tempData.length; i++) {
      if (UUID === tempData[i].uuid) {
        tempData[i].isSelected = event.target.checked;
      }
    }

    setuserList(tempData);
  };

  const selectOrDeselect = () => {
    // setting the select/deselect all text based on currently visible selected users
    const allUsersSelected = userList
      .filter((user) => user.isVisible)
      .every((user) => user.isSelected);
    if (allUsersSelected) {
      return "Deselect All";
    }
    return "Select All";
  };

  const selectAllUsers = (selectAll) => {
    // setting the operation(select/deselect all) to perform
    selectAll = selectAll === "Select All";
    const draft = JSON.parse(JSON.stringify(userList));

    for (let i in draft) {
      if (draft[i].isVisible === true) {
        draft[i].isSelected = selectAll;
      }
    }
    setuserList(draft);
  };

  const showEditModal = (objId) => {
    setuserDataObj(userList.find((s) => s.uuid === objId) || {});
    setEditUserDetailsOpen(true);
  };

  const showEditStatusModal = (objId, status) => {
    const datObj = JSON.parse(JSON.stringify(userList));
    let draft = datObj.find((s) => s.uuid === objId) || {};
    setuserStatusVal(status);
    draft.active = !status;
    setupdateStatusObj(draft);
    setisEditStatusOpen(true);
  };

  const showBulkUpdate = (status) => {
    if (userList.find((item) => item.isSelected === true)) {
      setbulkUserStatusVal(status);
      return setisEditBulkStatusOpen(true);
    }
    return addToast(infoToastConfig("Please Select User.", "error"));
  };

  const bulkUpdateUserStatus = (isConfirm) => {
    if (isConfirm) {
      const draft = userList
        .filter((value, index, arr) => {
          return value.isSelected === true;
        })
        .map((value, index, arr) => {
          return { ...value, active: bulkUserStatusVal };
        });
      saveForm(draft);
    }
  };

  const updateUserStatus = (isConfirm) => {
    if (isConfirm) {
      const draft = JSON.parse(JSON.stringify(userList));
      const userindex = draft.findIndex(
        (obj) => obj.uuid === updateStatusObj.uuid
      );
      draft[userindex].active = !draft[userindex].active;
      setuserList(draft);
      saveForm(updateStatusObj);
    }
  };

  const saveForm = async (userObj) => {
    const { meta } = await saveUserListForm(userObj);
    if (meta.requestStatus === "fulfilled") {
      addToast(
        infoToastConfig("Your changes have been saved successfully.", "success")
      );
    }
  };

  const updateCurrentPage = (page) => {
    setCurrentPage(page);
    const start = (page - 1) * pageSize;
    setStart(start);
    setEnd(start + pageSize);
  };

  return (
    <div>
      <LdsCard className="users-card">
        <LdsCard.Body>
          {!loading && userList.some((element) => element.isVisible) ? (
            <>
             {!isProfile &&  <div className="d-flex">
                <div className="mr-4">
                  <LdsButton
                    onClick={() => {
                      showBulkUpdate(true);
                    }}
                  >
                    Activate
                  </LdsButton>
                </div>
                <div>
                  <LdsButton
                    onClick={() => {
                      showBulkUpdate(false);
                    }}
                  >
                    Deactivate
                  </LdsButton>
                </div>
              </div>}
              <table
                id="user-list-table"
                className="user-list-table lds-table flat"
              >
                <thead>
                  <tr className="align-left">
                   {!isProfile &&  <th>
                      <LdsLink
                        onClick={() => selectAllUsers(selectOrDeselect())}
                      >
                        {selectOrDeselect()}
                      </LdsLink>
                    </th>}
                    <SortableHeader
                      headAlign={"align-left b"}
                      header={userTableHeader}
                      rowData={userList}
                      rowKeyName={[
                        "first_name",
                        "last_name",
                        "email_id",
                        "role",
                        "brand",
                        "status",
                        "active",
                      ]}
                      sortedRowData={setuserList}
                    />
                    <th>Edit brand</th>
                  </tr>
                </thead>
                <tbody>
                  {userList
                    .filter((item) => item.isVisible)
                    .map(
                      (item, index) =>
                        index >= start &&
                        index < end &&
                        item.isVisible && (
                          <tr id={item.uuid} key={index} className="align-left">
                         {!isProfile &&    <td>
                              <LdsCheckbox
                                id={"selected-" + item.uuid}
                                required={false}
                                name={"selected-" + item.uuid}
                                checked={item.isSelected}
                                value={item.uuid}
                                onChange={(event) =>
                                  selcheckBoxClicked(event, item.uuid)
                                }
                              ></LdsCheckbox>
                            </td>}
                            <td>{item.first_name}</td>
                            <td>{item.last_name}</td>
                            <td>{item.email_id}</td>
                            <td>{roleNameMapping(item.role)}</td>
                            <td>{convertArray(item.brand)}</td>
                            <td>{item.active ? "Active" : "InActive"}</td>
                            {!isProfile && <td>
                              <LdsLink
                                onClick={() => {
                                  showEditStatusModal(item.uuid, item.active);
                                }}
                              >
                                {item.active ? "Deactivate" : "Activate"}
                              </LdsLink>
                            </td>}
                            <td>
                              <span
                                onClick={() => {
                                  showEditModal(item.uuid);
                                }}
                              >
                                <LdsIcon name="PencilSimpleLineFill" inline />
                              </span>
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
              <div className="row">
                <div>
                  <LdsPagination
                    currentPage={currentPage}
                    setCurrentPage={updateCurrentPage}
                    totalPages={totalPages}
                  ></LdsPagination>
                </div>
              </div>
            </>
          ) : (
            <p>No Record Found</p>
          )}
        </LdsCard.Body>
      </LdsCard>
      <EditBulkUserStatus
        isEditBulkStatusOpen={isEditBulkStatusOpen}
        setisEditBulkStatusOpen={setisEditBulkStatusOpen}
        userstatus={bulkUserStatusVal}
        bulkUpdateUserStatus={bulkUpdateUserStatus}
      ></EditBulkUserStatus>
      <EditUserStatus
        isEditStatusOpen={isEditStatusOpen}
        setisEditStatusOpen={setisEditStatusOpen}
        userStatusVal={userStatusVal}
        updateUserStatus={updateUserStatus}
      ></EditUserStatus>
      <EditUserDetailsModal
        isEditUserDetailsOpen={isEditUserDetailsOpen}
        setEditUserDetailsOpen={setEditUserDetailsOpen}
        userDataObj={userDataObj}
        brandListData={Brands}
        saveForm={saveForm}
        isProfile={isProfile}
      ></EditUserDetailsModal>
    </div>
  );
};

export default UsersTable;
