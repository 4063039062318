import ctx from "../../plugins/axios";

/**
 * Gets the Product List by Brand.
 *
 * @returns {Promise<*>}
 */
const getApprovedSpecialties = async (selectedFilter) => {
  return await ctx.get(
    `/specialty_list/list_status?brand=${selectedFilter.brand}&indication=${selectedFilter.indication}&list_quarter=${selectedFilter.quarter}&department_field=${selectedFilter.department}&search_keyword=${selectedFilter.searchKeyword}`
  );
};

export default getApprovedSpecialties;
