import { LdsButton, LdsModal } from "@elilillyco/ux-lds-react";

const DeleteSpecialtyModal = ({
  open,
  delUUID,
  setModalOpen,
  updateSpecialty,
}) => {
  return (
    <LdsModal
      modalId="delete-specialty-modal"
      className="text-center"
      size="col-12 col-sm-8 col-md-8 col-lg-8"
      open={open}
      setModalOpen={setModalOpen}
    >
      <div class>
        <div class="textsize">
          <h2>Are you sure you want to delete.</h2>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div class="savecl">
            <LdsButton
              classes="button-icon-right blue-bdr-btn"
              onClick={() => setModalOpen(false)}
            >
              No
            </LdsButton>
            <LdsButton
              classes="button-icon-right blue-btn ml-4 mt-4"
              onClick={() => updateSpecialty(delUUID, false)}
            >
              Confirm Yes
            </LdsButton>
          </div>
        </div>
      </div>
    </LdsModal>
  );
};

export default DeleteSpecialtyModal;
