import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_RESPONSIBILITY_LIST,
  SET_SELECTED_RESPONSIBILITY,
  FETCH_RESPONSIBILITY_LIST,
} from "../../store/details";
import {
  LdsButton,
  LdsModal,
  LdsTable,
  LdsPagination,
  LdsSearch,
  LdsCheckbox,
  LdsLoadingSpinner,
} from "@elilillyco/ux-lds-react";
import "./../../assets/scss/details/addRespModal.scss";
import SortableHeader from "../shared/SortableHeader";

const AddMultiRespModal = ({
  isRespModalOpen,
  setIsRespModalOpen,
  updateMultiSelectedResponsibility,
  selectedValues,
  respmode,
}) => {
  const { responsibilityList, loading, selectedResponsibilties } = useSelector(
    ({ details }) => details
  );
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  let [data, setData] = useState(responsibilityList);
  let selectedResp = JSON.parse(JSON.stringify(selectedResponsibilties));

  const itemsPerPage = 10;
  const totalPg = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    let tempData = JSON.parse(JSON.stringify(responsibilityList));
    if (searchTerm !== "") {
      tempData = tempData.filter(
        (obj) =>
          obj.salesforce_code
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          obj.salesforce_description
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
    }

    setData(tempData);
  }, [responsibilityList, searchTerm]);

  const tableHead = ["Responsibility Code", "Description"];
  //below function is triggerd when close button is clicked
  function closeModal() {
    setIsRespModalOpen(false);
  }
  //below function is triggered when page no. is clicked
  function clickLink(e, value) {
    setCurrentPage(value);
  }
  //below function is triggerd when Next button is clicked in pagination component
  function clickNext() {
    setCurrentPage((prev) => {
      return prev + 1;
    });
  }
  //below function is triggerd when Prev button is clicked in pagination component
  function clickPrev() {
    setCurrentPage((prev) => {
      return prev - 1;
    });
  }
  //below function is triggerd when we submit the value entered in searchbar
  function submitSearch() {
    data = responsibilityList.filter(
      (obj) =>
        obj.salesforce_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        obj.salesforce_description
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    setData(data);
    setCurrentPage(1);
  }
  // below function is triggered when checkbox is clicked
  function checkBoxClicked(event, UUID) {
    let tempData = JSON.parse(JSON.stringify(responsibilityList));

    for (let i = 0; i < tempData.length; i++) {
      if (UUID === tempData[i].uuid) {
        if (event.target.checked === true) {
          tempData[i].isRespSelected = true;
          selectedResp.push(event.target.value);
        } else {
          tempData[i].isRespSelected = false;
          selectedResp = selectedResp.filter(
            (itm) => itm !== event.target.value
          );
        }
      }
    }

    dispatch(SET_RESPONSIBILITY_LIST(tempData));
    dispatch(SET_SELECTED_RESPONSIBILITY(selectedResp));
    // setData(tempData);
  }
  const handleAddForm = () => {
    let selectedRespCode = [];
    let str = "";
    let allresponsibilitylist = JSON.parse(JSON.stringify(responsibilityList));
    const filterData = allresponsibilitylist;
    for (let i = 0; i < filterData.length; i++) {
      if (selectedResp.includes(filterData[i].uuid)) {
        selectedRespCode.push(filterData[i].salesforce_code);
      }
    }
    if (selectedRespCode && selectedRespCode.length > 0) {
      str = selectedRespCode.join(",");
    }
    updateMultiSelectedResponsibility(str);
    setIsRespModalOpen(false);
    dispatch(SET_SELECTED_RESPONSIBILITY([]));
    // dispatch(FETCH_RESPONSIBILITY_LIST());
  };

  useEffect(() => {
    if (!isRespModalOpen) {
      if (responsibilityList && Array.isArray(responsibilityList)) {
        let tempData = JSON.parse(JSON.stringify(responsibilityList));

        for (let i = 0; i < tempData.length; i++) {
          tempData[i].isRespSelected = false;
        }

        setData(tempData);
        dispatch(SET_RESPONSIBILITY_LIST(tempData));
        dispatch(SET_SELECTED_RESPONSIBILITY([]));
        setCurrentPage(1);
      }
    }
  }, [isRespModalOpen]);

  return (
    <>
      <LdsModal
        modalId="add-resp-modal"
        open={isRespModalOpen}
        setModalOpen={setIsRespModalOpen}
        heading={`${respmode === "add" ? "Add" : "Remove"} Responsibility`}
      >
        <div>
          <div className="col-12 col-md-6">
            <strong>Search Responsibility </strong>
            <LdsSearch
              id={"searchResponsibility"}
              onChange={setSearchTerm}
              value={searchTerm}
              onSubmit={submitSearch}
            />
          </div>
          {loading ? (
            <LdsLoadingSpinner size={90} />
          ) : (
            <div>
              {data.length > 0 ? (
                <>
                  <LdsTable id="specialities-table" flat>
                    <thead>
                      <tr>
                        <SortableHeader
                          headAlign={"align-left b"}
                          header={tableHead}
                          rowData={data}
                          rowKeyName={[
                            "salesforce_code",
                            "salesforce_description",
                          ]}
                          sortedRowData={setData}
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {data.map(
                        (itm, index) =>
                          index >= indexOfFirstItem &&
                          index < indexOfLastItem && (
                            <>
                              <tr>
                                <td>
                                  <LdsCheckbox
                                    required={false}
                                    value={itm.uuid}
                                    name={"Responsibility-checkbox-" + itm.uuid}
                                    id={"Responsibility-checkbox-" + itm.uuid}
                                    onChange={(event) =>
                                      checkBoxClicked(event, itm.uuid)
                                    }
                                    checked={itm.isRespSelected}
                                  ></LdsCheckbox>
                                  {itm.salesforce_code}
                                </td>
                                <td>{itm.salesforce_description}</td>
                              </tr>
                            </>
                          )
                      )}
                    </tbody>
                  </LdsTable>
                  <div className="row">
                    <div className="col">
                      <LdsPagination
                        className="floatRight"
                        currentPage={currentPage}
                        onLinkClick={clickLink}
                        totalPages={totalPg}
                        onNextClick={clickNext}
                        onPrevClick={clickPrev}
                      />
                    </div>
                  </div>
                  <div className="floatRight">
                    <LdsButton
                      iconPosition="before"
                      classes="button-icon-left blue-bdr-btn"
                      onClick={closeModal}
                    >
                      Cancel
                    </LdsButton>
                    <span className="buttonMargin">
                      <LdsButton
                        iconPosition="before"
                        classes="button-icon-left blue-btn"
                        onClick={handleAddForm}
                      >
                        {`${respmode === "add" ? "Add" : "Remove"}`}
                      </LdsButton>
                    </span>
                  </div>
                </>
              ) : (
                <p>No Record Found</p>
              )}
            </div>
          )}
        </div>
      </LdsModal>
    </>
  );
};
export default AddMultiRespModal;
