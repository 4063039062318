import ctx from '../plugins/axios';

// eslint-disable-next-line import/no-anonymous-default-export
export default async () => {
  try {
    const data = await ctx.get('/user');
    return data;
  } catch (error) {
    return error;
  }
};
