import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  usersList,
  updateUserList,
  getBrands,
  BrandbuList,
  buLists,
  updateBrandBU,
  getBuProductList,
  importList,
  IncentivizedJobTrigger,
} from "../api/admin/index";

const initialState = {
  userTableList: [],
  Brands: [],
  BUList: [],
  brandBUList: [],
  productList: [],
  error: false,
  uploaded: false,
  importMsg: [],
  jobresp: "",
  BULoading: true,
  loading: false,
};

const reducers = {
  SET_USER_LIST(state, userTableList) {
    state.userTableList = userTableList;
  },

  SET_JOB_RESP(state, jobresp) {
    state.jobresp = jobresp;
  },

  SET_BRANDS_LIST(state, Brands) {
    state.Brands = Brands;
  },

  SET_BRAND_BU_LIST(state, brandBUList) {
    state.brandBUList = brandBUList;
  },

  SET_BU_LIST(state, BUData) {
    state.BUList = BUData;
  },

  SET_BU_PRODUCT_LIST(state, BuList) {
    state.productList = BuList;
  },

  SET_ERROR(state, isError) {
    state.error = isError;
  },

  SET_UPLOADED(state, isUploaded) {
    state.uploaded = isUploaded;
  },

  SET_IMPORTMSG(state, msg) {
    state.importMsg = msg;
  },
};

const UPDATE_USER_LIST = createAsyncThunk(
  "admin/UPDATE_USER_LIST",
  async (object, { rejectWithValue, dispatch }) => {
    const { data, status } = await updateUserList(object);
    if (data.statusCode === 200) {
      dispatch(FETCH_USER_LIST());
    } else {
      return rejectWithValue(data);
    }
  }
);
const FETCH_USER_LIST = createAsyncThunk("admin/FETCH_USER_LIST", async () => {
  const { data, status } = await usersList();

  return { data, status };
});

const FETCH_BRAND_LIST = createAsyncThunk(
  "admin/FETCH_BRAND_LIST",
  async () => {
    const { data, status } = await getBrands();

    return { data, status };
  }
);

const FETCH_BRAND_BU_LIST = createAsyncThunk(
  "admin/FETCH_BRAND_BU_LIST",
  async () => {
    const { data, status } = await BrandbuList();

    return { data, status };
  }
);

const FETCH_BRAND_BU_LIST_FULFILLED = (state, payload) =>
  (state.brandBUList =
    payload.data.data.map((element) => ({ ...element, show: true })) || []);

const FETCH_BU_LIST = createAsyncThunk("admin/FETCH_BU_LIST", async () => {
  const { data, status } = await buLists();

  return { data, status };
});

const FETCH_BU_LIST_FULFILLED = (state, payload) =>
  (state.BUList = payload.data.data.map((option) => ({
    value: option.bu,
    label: option.bu,
  })));

const UPDATE_BRAND_BU_LIST = createAsyncThunk(
  "admin/UPDATE_BRAND_BU_LIST",
  async (object, { dispatch }) => {
    const { data, status } = await updateBrandBU(object);
    if (status === 200) {
      dispatch(FETCH_BU_DATA());
    }
    return { data, status };
  }
);

const FETCH_BU_PRODUCT_LIST = createAsyncThunk(
  "admin/FETCH_BU_PRODUCT_LIST",
  async () => {
    const { data, status } = await getBuProductList();

    return { data, status };
  }
);

const FETCH_BU_PRODUCT_LIST_FULFILLED = (state, payload) =>
  (state.productList = payload.data.data.map((option, index) => ({
    value: option.prod_nm,
    label: option.prod_nm,
    uuid: option.uuid,
    name: `option${index}`,
  })));

const FETCH_BU_DATA = createAsyncThunk("admin/FETCH_BU_DATA", async () =>
  (await Promise.all([BrandbuList(), getBuProductList(), buLists()])).map(
    ({ data, status }) => {
      return { data, status };
    }
  )
);

const POST_UPLOAD = createAsyncThunk(
  "admin/POST_UPLOAD",
  async (object, { rejectWithValue }) => {
    try {
      const { data, status } = await importList(object);

      return { data, status };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const FETCH_INCENTIVIZED_JOB = createAsyncThunk(
  "admin/FETCH_INCENTIVIZED_JOB",
  async (object) => {
    const { data, status } = await IncentivizedJobTrigger(object);

    return { data, status };
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers,
  extraReducers(builder) {
    builder.addCase(UPDATE_USER_LIST.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(UPDATE_USER_LIST.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(UPDATE_USER_LIST.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(FETCH_USER_LIST.fulfilled, (state, { payload }) => {
      const { data: users, status } = payload;
      state.userTableList = users.data.map((userdata) => {
        userdata.isSelected = false;
        userdata.isVisible = true;
        return userdata;
      });
      state.loading = false;
    });
    builder.addCase(FETCH_USER_LIST.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(FETCH_USER_LIST.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(FETCH_BRAND_LIST.fulfilled, (state, { payload }) => {
      const { data: users, status } = payload;
      state.Brands =
        users.data.map((option) => {
          return { ...option, name: option.value };
        }) || [];
      state.loading = false;
    });
    builder.addCase(FETCH_BRAND_LIST.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(FETCH_BRAND_LIST.rejected, (state, action) => {});

    // FETCH_BRAND_BU_LIST
    builder.addCase(FETCH_BRAND_BU_LIST.pending, (state, action) => {
      state.BULoading = true;
    });
    builder.addCase(FETCH_BRAND_BU_LIST.fulfilled, (state, { payload }) => {
      FETCH_BRAND_BU_LIST_FULFILLED(state, payload);
      state.BULoading = false;
    });
    builder.addCase(FETCH_BRAND_BU_LIST.rejected, (state, action) => {
      state.BULoading = false;
    });

    // FETCH_BU_LIST
    builder.addCase(FETCH_BU_LIST.pending, (state, action) => {
      state.BULoading = true;
    });
    builder.addCase(FETCH_BU_LIST.fulfilled, (state, { payload }) => {
      FETCH_BU_LIST_FULFILLED(state, payload);
      state.BULoading = false;
    });
    builder.addCase(FETCH_BU_LIST.rejected, (state, action) => {
      state.BULoading = false;
    });

    // UPDATE_BRAND_BU_LIST
    builder.addCase(UPDATE_BRAND_BU_LIST.pending, (state, action) => {
      state.BULoading = true;
    });
    builder.addCase(UPDATE_BRAND_BU_LIST.fulfilled, (state, { payload }) => {});
    builder.addCase(UPDATE_BRAND_BU_LIST.rejected, (state, action) => {
      state.BULoading = false;
    });

    // FETCH_BU_PRODUCT_LIST
    builder.addCase(FETCH_BU_PRODUCT_LIST.pending, (state, action) => {
      state.BULoading = true;
    });
    builder.addCase(FETCH_BU_PRODUCT_LIST.fulfilled, (state, { payload }) => {
      FETCH_BU_PRODUCT_LIST_FULFILLED(state, payload);
      state.BULoading = false;
    });
    builder.addCase(FETCH_BU_PRODUCT_LIST.rejected, (state, action) => {
      state.BULoading = false;
    });

    // FETCH_BU_DATA
    builder.addCase(FETCH_BU_DATA.pending, (state, action) => {
      state.BULoading = true;
    });
    builder.addCase(FETCH_BU_DATA.fulfilled, (state, { payload }) => {
      FETCH_BRAND_BU_LIST_FULFILLED(state, payload[0]);
      FETCH_BU_PRODUCT_LIST_FULFILLED(state, payload[1]);
      FETCH_BU_LIST_FULFILLED(state, payload[2]);
      state.BULoading = false;
    });
    builder.addCase(FETCH_BU_DATA.rejected, (state, action) => {
      state.BULoading = false;
    });

    builder.addCase(POST_UPLOAD.fulfilled, (state, action) => {
      const { data: list, status } = action.payload;
      if (status !== 200) {
        state.uploaded = true;
        state.importMsg = list.data;
      } else {
        state.uploaded = true;
        state.importMsg = list.data;
      }
    });
    builder.addCase(POST_UPLOAD.pending, (state, action) => {});
    builder.addCase(POST_UPLOAD.rejected, (state, action) => {
      if (action.payload.response.data.statusCode !== 200) {
        state.uploaded = false;
        state.importMsg = action.payload.response.data.data;
      } else {
        state.uploaded = true;
        state.importMsg = action.payload.response.data.data;
      }
    });
    builder.addCase(FETCH_INCENTIVIZED_JOB.fulfilled, (state, { payload }) => {
      const { data: respdata, status } = payload;
      state.jobresp = respdata || [];
    });
    builder.addCase(FETCH_INCENTIVIZED_JOB.pending, (state, action) => {});
    builder.addCase(FETCH_INCENTIVIZED_JOB.rejected, (state, action) => {});
  },
});

export const {
  SET_USER_LIST,
  SET_JOB_RESP,
  SET_BRANDS_LIST,
  SET_BRAND_BU_LIST,
  SET_BU_LIST,
  SET_BU_PRODUCT_LIST,
  SET_ERROR,
  SET_UPLOADED,
  SET_IMPORTMSG,
} = adminSlice.actions;
export {
  UPDATE_USER_LIST,
  FETCH_USER_LIST,
  UPDATE_BRAND_BU_LIST,
  POST_UPLOAD,
  FETCH_INCENTIVIZED_JOB,
  FETCH_BU_DATA,
  FETCH_BRAND_LIST,
};

export default adminSlice.reducer;
