import { LdsModal, LdsButton } from "@elilillyco/ux-lds-react";
import "./../../assets/scss/details/deleteSpecModal.scss";

const ConfirmDeleteModal = ({
  isDelModalOpen,
  setIsDelModalOpen,
  confirmDelete,
}) => {
  const ConfirmDeleteDetails = (isConfirm) => {
    // this.busEmit("modal:hide", { id: "confirm-delete-modal" });
    // this.$emit("confirmDelete", isConfirm);
    confirmDelete(isConfirm);
  };
  return (
    <>
      <LdsModal
        className="text-center"
        modalId="confirm-delete-moda"
        open={isDelModalOpen}
        setModalOpen={setIsDelModalOpen}
      >
        <div>
          <div>
            <h2>Are you sure you want to delete.</h2>
            <p>
              If YES Selected Row for the speciality list will be get
              <strong> “DELETED”</strong> only after submit .
            </p>
          </div>
        </div>
        <div className="savecl">
          <LdsButton
            iconPosition="before"
            className="button-icon-left blue-bdr-btn test mr-4"
            onClick={() => {
              setIsDelModalOpen(false);
              ConfirmDeleteDetails("No");
            }}
          >
            No
          </LdsButton>
          <LdsButton
            iconPosition="before"
            className="button-icon-left  blue-btn"
            onClick={() => {
              setIsDelModalOpen(false);
              ConfirmDeleteDetails("Yes");
            }}
          >
            Confirm Yes
          </LdsButton>
        </div>
      </LdsModal>
    </>
  );
};
export default ConfirmDeleteModal;
