const infoToastConfig = (toastMessage, variant, timeout = 1500) => {
  return {
    toastMessage: toastMessage,
    actionText: "",
    actionCallback: () => console.log("View clicked"),
    variant: variant,
    position: "top",
    align: "center",
    dismissible: true,
    light: false,
    timeout: timeout,
    inline: false,
    autoDismiss: true,
  };
};

export default infoToastConfig;
