import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ListApprovedModal from "./ListApprovedModal";
import AddMultiRespModal from "./AddMultiResponsibilitiesModal";
import {
  LdsButton,
  LdsModal,
  useLdsModal,
  LdsLink,
  LdsIcon,
  LdsCard,
  LdsBadge,
  LdsTextField,
  LdsSearch,
  useToastContext,
  LdsTable,
  LdsCheckbox,
  LdsPagination,
} from "@elilillyco/ux-lds-react";
import {
  SET_SPECIALITYLIST,
  FETCH_SPECIALITYLIST,
  FETCH_COMMENTLIST,
  FETCH_RESPONSIBILITY_LIST,
  FETCH_ADD_SPECIALTIES_LIST,
  UPDATE_SPECIALITYLIST,
  SET_RESP_INDEX,
  SET_SPECIALITY_LIST,
} from "../../store/details";
import { FETCH_USER_LIST } from "../../store/admin";
import { useEffect, useState } from "react";
import "../../assets/scss/details/details.scss";
import exportExcelSheet from "../../utils/overview/exportExcelSheet";
import EditBrandIndication from "../details/EditBrandIndication";
import infoToastConfig from "../shared/Toast";
import SearchResponsibilityModal from "../details/SearchResponsibilityModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import SortableHeader from "../shared/SortableHeader";

const pageSize = 10;

const OverviewTable = ({
  showAddedSpecialties,
  selectedValues,
  setselectedValues,
  visibleSpecialties,
  setVisibleSpecialties,
}) => {
  const {
    isModalOpen: isListAppModalOpen,
    setIsModalOpen: setListAppModalOpen,
  } = useLdsModal();
  const { isModalOpen: isRespModalOpen, setIsModalOpen: setIsRespModalOpen } =
    useLdsModal();
  const { isModalOpen: isDelModalOpen, setIsModalOpen: setIsDelModalOpen } =
    useLdsModal();
  const dispatch = useDispatch();
  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const { toasts, addToast, removeToast, clearAllToasts } = useToastContext();

  const [editTitle, seteditTitle] = useState(false);
  const [editTitleValue, seteditTitleValue] = useState("");
  const [isSaveDisabled, setisSaveDisabled] = useState(false);
  const [editBrandIndication, seteditBrandIndication] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [respmode, setrespmode] = useState("");

  const [msg, setMsg] = useState("");
  const [deleteMultipleSpecialties, setdeleteMultipleSpecialties] =
    useState(false);
  const [delSpeclIndex, setdelSpeclIndex] = useState("");
  const [updateResCodeIndex, setupdateResCodeIndex] = useState("");
  const [addedSpecialtyCodes, setAddedSpecialtyCodes] = useState([]);
  const [uncommonRespCodes, setUncommonRespCodes] = useState([]);
  const [delSpecialtyCodes, setDelSpecialtyCodes] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(pageSize);

  const userRole = sessionStorage.getItem("role") || "";
  const {
    loading,
    specialtyList,
    comments,
    responsibilityList,
    addspecialtyList,
    respCodeIndex,
    commentUserList,
    error,
  } = useSelector(({ details }) => details);
  const { uuid, emailId } = useSelector(({ user }) => user);
  useEffect(() => {
    dispatch(FETCH_USER_LIST());
  }, []);
  const { userTableList } = useSelector(({ admin }) => admin);
  let useruuids = userTableList
    .filter((value) => value.email_id === emailId && value.active === true)
    .map((value) => value.uuid);
  useEffect(() => {
    setTotalPages(
      Math.ceil(
        visibleSpecialties?.specialties?.filter((specialty) => specialty.show)
          .length / pageSize
      )
    );
  }, [visibleSpecialties, showAddedSpecialties]);
  const thead = [
    "Specialty Code",
    "Specialty Description",
    "Sales Force Approved (Incentivized)",
    "Responsibility Code",
    "",
  ];
  const theadSf = ["Specialty Code", "Specialty Description", ""];
  const CheckViewerRole = () => {
    if (userRole === "spclty_hub_viewer") {
      return false;
    }
    return true;
  };
  const downloadcsv = () => {
    const result = [];
    const splitop = [];
    const x = JSON.parse(JSON.stringify(specialtyList));
    for (let i = 0; i < x.specialties.length; i++) {
      x.specialties[i].responsibility_code = x.specialties[i]
        .responsibility_code
        ? x.specialties[i].responsibility_code.split(",")
        : [];
      if (x.specialties[i].responsibility_code.length) {
        for (let j = 0; j < x.specialties[i].responsibility_code.length; j++) {
          splitop.push({
            ...x.specialties[i],
            responsibility_code: x.specialties[i].responsibility_code[j],
          });
        }
      } else {
        splitop.push({ ...x.specialties[i], responsibility_code: "" });
      }
    }
    x.specialties = splitop;
    for (let i = 0; i < x.specialties.length; i++) {
      result.push({ ...x, specialties: x.specialties[i] });
    }
    const final = [];
    result.forEach((value, index, arr) => {
      final.push({
        Brand: value.brand,
        "CreatedBy MailId": value.created_by.email_id,
        CreatedBy:
          value.created_by.first_name + " " + value.created_by.last_name,
        "Created Date": value.created_date,
        Department: value.department_field,
        Indication: value.indication,
        Quarter: value.list_quarter,
        "List Status": value.list_status,
        "List Title": value.list_title,
        Incentivized: value.specialties.incentivized,
        "Responsibility Code": value.specialties.responsibility_code,
        "Specialty Code": value.specialties.specialty_code,
        "Specialty Description": value.specialties.specialty_description,
        "UpdatedBy MailId": value.updated_by.email_id,
        UpdatedBy:
          value.updated_by.first_name + " " + value.updated_by.last_name,
        "Updated Date": value.updated_date,
      });
    });
    exportExcelSheet(final, "SpecialtyList");
  };
  const convertDateFormat = (dateObj) => {
    const dt = new Date(`${dateObj}Z`);
    return dateObj ? moment(dt).format("dddd, MMMM Do YYYY, h:mm:ss a") : "";
  };
  const getCreateUpdateBy = (details) => {
    if (details && details.first_name && details.last_name) {
      return `${details.first_name} ${details.last_name}`;
    }
    return "";
  };
  const checkListStatus = () => {
    if (
      specialtyList &&
      specialtyList.list_status !== "APPROVED" &&
      specialtyList.list_status !== "ARCHIVE" &&
      sessionStorage.getItem("role") !== "spclty_hub_viewer"
    ) {
      return true;
    }

    return false;
  };
  const editSpecialityTitle = () => {
    seteditTitle(!editTitle);
    seteditTitleValue(specialtyList.list_title);
  };
  const checkListStatusSuperAdmin = () => {
    if (sessionStorage.getItem("role") === "spclty_hub_super_admin") {
      return true;
    }

    return false;
  };
  const badgeColourClass = () => {
    if (specialtyList.list_status === "DRAFT") {
      return "draft-badge";
    } else {
      return "green-btn";
    }
  };
  const saveSpecialityTitle = async () => {
    const updatedObjID = specialtyList.uuid;
    const lisTitleObj = {
      list_title: editTitleValue,
      updated_by: uuid, // Pass UUID
    };
    const x = await dispatch(
      UPDATE_SPECIALITYLIST({ updatedList: lisTitleObj, UUID: updatedObjID })
    );
    // this.$bus.$emit('success-toast');
  };
  const closeEditTitle = () => {
    seteditTitle(false);
  };
  const editBrandIndicationTitle = () => {
    seteditBrandIndication(true);
  };
  const closeBrandIndicationEdit = () => {
    seteditBrandIndication(false);
  };
  const saveMetaData = (metaObj) => {
    const updatedObjID = specialtyList.uuid;
    dispatch(
      UPDATE_SPECIALITYLIST({ updatedList: metaObj, UUID: updatedObjID })
    );
    // this.updateSpecListDeails({ updatedList: metaObj, UUID: updatedObjID });
    // this.$bus.$emit('success-toast');
  };
  const checkSpecialtyListLen = (slist) => {
    if (slist && slist.specialties && slist.specialties.length > 0) {
      return true;
    }
    return false;
  };

  const filterSpecialityCodeList = () => {
    const tempVisibleSpecialties = JSON.parse(
      JSON.stringify(visibleSpecialties)
    );
    if (searchText) {
      tempVisibleSpecialties.specialties = visibleSpecialties.specialties.map(
        (s) => {
          s.show =
            s.responsibility_code
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            s.specialty_code.toLowerCase().includes(searchText.toLowerCase()) ||
            s.specialty_description
              .toLowerCase()
              .includes(searchText.toLowerCase());
          return s;
        }
      );
    } else {
      tempVisibleSpecialties.specialties = visibleSpecialties.specialties.map(
        (s) => ({
          ...s,
          show: true,
        })
      );
    }
    setVisibleSpecialties(tempVisibleSpecialties);

    setTotalPages(
      Math.ceil(
        tempVisibleSpecialties.specialties.filter((specialty) => specialty.show)
          .length / pageSize
      )
    );
    updateCurrentPage(1);
  };

  const showAddRespButton = () => {
    // eslint-disable-next-line no-constant-condition
    if (
      (userRole === "spclty_hub_admin" &&
        specialtyList.list_status !== "APPROVED" &&
        specialtyList.list_status !== "ARCHIVE") ||
      (userRole === "spclty_hub_hcp_sf_marketer" &&
        specialtyList.list_status !== "APPROVED" &&
        specialtyList.list_status !== "ARCHIVE") ||
      (userRole === "spclty_hub_incentivized_approver" &&
        specialtyList.list_status !== "APPROVED" &&
        specialtyList.list_status !== "ARCHIVE") ||
      (userRole === "spclty_hub_super_admin" &&
        specialtyList.list_status !== "APPROVED" &&
        specialtyList.list_status !== "ARCHIVE")
    ) {
      return true;
    }
    return false;
  };
  const showaddMultiResponsibilitiesModal = (respmode) => {
    setrespmode(respmode);
    // this.respmode = respmode;
    if (selectedValues.length === 0) {
      addToast(
        infoToastConfig(
          "Please Select Specialties in which  Responsibility Code needs to be added.",
          "error"
        )
      );
      return;
    }
    setIsRespModalOpen(true);
  };
  const updateMultiSelectedResponsibility = (selectedResponsibility) => {
    if (respmode === "add") {
      for (let i = 0; i < selectedValues.length; i++) {
        const checkedSpecialties =
          visibleSpecialties.specialties[selectedValues[i]];
        checkedSpecialties.responsibility_code = Array.from(
          new Set(
            (checkedSpecialties.responsibility_code !== ""
              ? checkedSpecialties.responsibility_code +
                "," +
                selectedResponsibility
              : checkedSpecialties.responsibility_code + selectedResponsibility
            ).split(",")
          )
        ).toString();
        checkedSpecialties.incentivized = true;
      }
      selectedResponsibility = "";
      // dispatch(FETCH_RESPONSIBILITY_LIST());
    }
    if (respmode === "remove") {
      const selectedResponsibilityArr = selectedResponsibility.split(",");
      for (let i = 0; i < selectedValues.length; i++) {
        const checkedSpecialties =
          visibleSpecialties.specialties[selectedValues[i]];
        checkedSpecialties.responsibility_code =
          checkedSpecialties.responsibility_code
            .split(",")
            .filter((n) => !selectedResponsibilityArr.includes(n))
            .toString();
        checkedSpecialties.responsibility_code === ""
          ? (checkedSpecialties.incentivized = false)
          : (checkedSpecialties.incentivized = true);
      }
      selectedResponsibility = "";
    }
  };
  const showDeleteButton = () => {
    if (
      userRole !== "spclty_hub_viewer" &&
      userRole !== "spclty_hub_incentivized_approver" &&
      userRole !== "spclty_hub_hcp_sf_marketer" &&
      specialtyList.list_status !== "APPROVED" &&
      specialtyList.list_status !== "ARCHIVE"
    ) {
      return true;
    }
    return false;
  };
  const deleteMultiple = () => {
    if (selectedValues.length === 0) {
      addToast(
        infoToastConfig(
          "Please Select Specialties Which Needs to be Deleted.",
          "error"
        )
      );
      return;
    }
    setdeleteMultipleSpecialties(true);
    // this.deleteMultipleSpecialties = true;
    setIsDelModalOpen(true);
  };
  const getTableColumn = () => {
    if (
      userRole === "spclty_hub_hcp_marketer" ||
      userRole === "spclty_hub_omnichannel_advisor" ||
      userRole === "spclty_hub_mlr_approver" ||
      userRole === "spclty_hub_mlr_reviewer"
    ) {
      // return col.splice(col.length - 2, 2);
      return theadSf;
    }
    return thead;
  };

  const selectAllSpecialties = (selectAll) => {
    // setting the operation(select/deselect all) to perform
    selectAll = selectAll === "Select All";
    const visibleSpecialtiesCopy = JSON.parse(
      JSON.stringify(visibleSpecialties)
    );

    // copy of selected specialties
    let chkbx = [...selectedValues];

    for (let i in visibleSpecialtiesCopy.specialties) {
      // converting i to number
      i = Number(i);
      // only selecting/deselectiong the visible specialties
      if (visibleSpecialtiesCopy.specialties[i].show) {
        visibleSpecialtiesCopy.specialties[i].selectedSpecialty = selectAll;

        // adding/removing current specialty in selected specialties
        if (selectAll) {
          if (!chkbx.includes(i)) {
            chkbx.push(i);
          }
        } else {
          const index = chkbx.indexOf(i);
          if (index > -1) {
            chkbx.splice(index, 1);
          }
        }
      }
    }
    setselectedValues(chkbx);
    setVisibleSpecialties(visibleSpecialtiesCopy);
  };

  const selectOrUnselect = () => {
    // setting the select/deselect all text based on currently visible selected specialties
    const allSpecialtiesSelected = visibleSpecialties?.specialties
      ?.filter((sp) => sp.show)
      ?.every((sp) => sp.selectedSpecialty);
    if (allSpecialtiesSelected) {
      return "Deselect All";
    }
    return "Select All";
  };

  const checkOmniOrHCPOrMLRRole = () => {
    // eslint-disable-next-line no-constant-condition
    if (
      userRole === "spclty_hub_hcp_marketer" ||
      userRole === "spclty_hub_omnichannel_advisor" ||
      userRole === "spclty_hub_mlr_approver" ||
      userRole === "spclty_hub_mlr_reviewer"
    ) {
      return true;
    }
    return false;
  };

  /**
   * Gets the index of current row from visibleSpecialties.specialties
   *
   * @param {Object} item row data object
   * @returns index of data in visibleSpecialties.specialties
   */
  const getRowIndex = (item) => {
    const data = visibleSpecialties.specialties;
    return data.findIndex(
      (element) => element.specialty_code === item.specialty_code
    );
  };

  const respCodeContent = (item) => {
    const index = getRowIndex(item);
    return visibleSpecialties.specialties[index].responsibility_code
      ? visibleSpecialties.specialties[index].responsibility_code.replace(
          /,/g,
          ", "
        )
      : "";
  };
  const showSearchResponsibilityModal = (item) => {
    if (item.incentivized !== true) return;
    const index = getRowIndex(item);
    setupdateResCodeIndex(index);
    // this.updateResCodeIndex = index;
    // this.updateRespCodeIndex(index);
    // this.busEmit("modal:show", { id: "searchResponsibilityModal" });
    dispatch(SET_RESP_INDEX(index));
    setIsModalOpen(true);
  };
  const checkRoleSFMarketer = () => {
    if (
      sessionStorage.getItem("role") === "spclty_hub_hcp_sf_marketer" ||
      sessionStorage.getItem("role") === "spclty_hub_incentivized_approver"
    ) {
      return true;
    }
    return false;
  };
  const deleteSpecialityList = (item) => {
    const index = getRowIndex(item);
    setdeleteMultipleSpecialties(false);
    setdelSpeclIndex(index);
    setIsDelModalOpen(true);
  };
  const DraftSpecialityDetails = () => {
    visibleSpecialties.list_status = "DRAFT";
    const updatedObjID = visibleSpecialties.uuid;
    delete visibleSpecialties.uuid;
    delete visibleSpecialties.updated_date;
    delete visibleSpecialties.created_by;
    for (let itm in visibleSpecialties.specialties) {
      delete visibleSpecialties.specialties[itm].show;
    }

    visibleSpecialties.updated_by = uuid;
    dispatch(
      UPDATE_SPECIALITYLIST({
        updatedList: visibleSpecialties,
        UUID: updatedObjID,
      })
    );
    addToast(
      infoToastConfig("Your changes have been saved successfully.", "success")
    );
  };
  const confirmSubmission = () => {
    if (
      visibleSpecialties.specialties.find(
        (val) => val.incentivized === true && val.responsibility_code === ""
      ) !== undefined
    ) {
      addToast(infoToastConfig("Please Select Responsibility Code.", "error"));
      return;
    }
    if (visibleSpecialties.hasOwnProperty("created")) {
      setAddedSpecialtyCodes(
        visibleSpecialties.created.map((itm) => {
          return {
            ...itm,
            status: "Created",
          };
        })
      );
    } else {
      setAddedSpecialtyCodes([]);
    }
    if (visibleSpecialties.hasOwnProperty("updated")) {
      setUncommonRespCodes(
        visibleSpecialties.updated.map((itm) => {
          return {
            ...itm,
            status: "Updated",
          };
        })
      );
    } else {
      setUncommonRespCodes([]);
    }
    if (visibleSpecialties.hasOwnProperty("deleted")) {
      setDelSpecialtyCodes(
        visibleSpecialties.deleted.map((itm) => {
          return {
            ...itm,
            status: "Deleted",
          };
        })
      );
    } else {
      setDelSpecialtyCodes([]);
    }
    if (
      userRole === "spclty_hub_mlr_approver" &&
      !visibleSpecialties.list_quarter &&
      visibleSpecialties.list_status === "REQUEST FOR APPROVAL"
    ) {
      setMsg("Are you sure want to approve ?");
    } else if (userRole === "spclty_hub_mlr_reviewer") {
      let notSubmittedreview = visibleSpecialties.reviewerlist.filter(
        (item) => visibleSpecialties.reviewsubmitted.indexOf(item) === -1
      );
      if (
        notSubmittedreview.length === 1 &&
        useruuids.filter((value) => value === notSubmittedreview[0]).length ===
          1
      ) {
        setMsg("Are you sure want to request for approval ?");
      } else {
        setMsg("Are you sure you want to Submit Review ?");
      }
    } else if (
      userRole === "spclty_hub_incentivized_approver" &&
      visibleSpecialties.list_quarter &&
      visibleSpecialties.list_status === "REQUEST FOR APPROVAL"
    ) {
      setMsg("Are you sure want to approve ?");
    } else if (
      (userRole === "spclty_hub_hcp_sf_marketer" ||
        userRole === "spclty_hub_incentivized_approver") &&
      visibleSpecialties.list_quarter
    ) {
      setMsg("Are you sure want to request for approval ?");
    } else if (
      (userRole === "spclty_hub_hcp_sf_marketer" ||
        userRole === "spclty_hub_incentivized_approver") &&
      !visibleSpecialties.list_quarter
    ) {
      setMsg("Are you sure want to request for Review ?");
    } else if (
      userRole === "spclty_hub_mlr_approver" &&
      visibleSpecialties.list_status !== "REQUEST FOR APPROVAL"
    ) {
      setMsg("Are you sure want to request for approval ?");
    } else if (
      (userRole !== "spclty_hub_hcp_sf_marketer" ||
        userRole !== "spclty_hub_incentivized_approver") &&
      visibleSpecialties.list_quarter
    ) {
      setMsg("Are you sure want to request for approval ?");
    } else {
      setMsg("Are you sure want to request for Review ?");
    }
    setListAppModalOpen(true);
  };
  const updateSpecialityDetails = (approverUserList) => {
    let toastMsg;
    if (
      userRole === "spclty_hub_mlr_approver" &&
      !visibleSpecialties.list_quarter &&
      visibleSpecialties.list_status === "REQUEST FOR APPROVAL"
    ) {
      visibleSpecialties.list_status = "APPROVED";
      toastMsg = "Specialty list has been approved.";
    } else if (userRole === "spclty_hub_mlr_reviewer") {
      const filteredArray = useruuids.filter((value) =>
        visibleSpecialties.reviewerlist.includes(value)
      );
      if (!visibleSpecialties.reviewsubmitted?.includes(filteredArray[0])) {
        visibleSpecialties.reviewsubmitted?.push(filteredArray[0]);
      }
      if (
        visibleSpecialties.reviewerlist?.filter(
          (item) => !visibleSpecialties.reviewsubmitted.includes(item)
        ).length === 0
      ) {
        visibleSpecialties.list_status = "REQUEST FOR APPROVAL";
        toastMsg = "Specialty list has been sent for the approval.";
      } else {
        // visibleSpecialties.list_status = "REQUEST FOR APPROVAL";
        toastMsg = "Specialty list has been Reviewed.";
      }
    } else if (
      userRole === "spclty_hub_incentivized_approver" &&
      visibleSpecialties.list_quarter &&
      visibleSpecialties.list_status === "REQUEST FOR APPROVAL"
    ) {
      visibleSpecialties.list_status = "APPROVED";
      toastMsg = "Specialty list has been approved.";
    } else if (
      (userRole === "spclty_hub_hcp_sf_marketer" ||
        userRole === "spclty_hub_incentivized_approver") &&
      !visibleSpecialties.list_quarter
    ) {
      visibleSpecialties.list_status = "REQUEST FOR REVIEW";
      toastMsg = "Specialty list has been sent for review.";
    } else if (
      (userRole === "spclty_hub_hcp_sf_marketer" ||
        userRole === "spclty_hub_incentivized_approver") &&
      visibleSpecialties.list_quarter
    ) {
      visibleSpecialties.list_status = "REQUEST FOR APPROVAL";
      toastMsg = "Specialty list has been sent for approval.";
    } else if (
      userRole === "spclty_hub_mlr_approver" &&
      !visibleSpecialties.list_quarter &&
      visibleSpecialties.list_status !== "REQUEST FOR APPROVAL"
    ) {
      visibleSpecialties.list_status = "REQUEST FOR APPROVAL";
      toastMsg = "Specialty list has been sent for the approval.";
    } else if (
      (userRole !== "spclty_hub_hcp_sf_marketer" ||
        userRole !== "spclty_hub_incentivized_approver") &&
      visibleSpecialties.list_quarter
    ) {
      visibleSpecialties.list_status = "REQUEST FOR APPROVAL";
      toastMsg = "Specialty list has been sent for the approval.";
    } else {
      visibleSpecialties.list_status = "REQUEST FOR REVIEW";
      toastMsg = "Specialty list has been sent for review.";
    }
    const updatedObjID = visibleSpecialties.uuid;
    for (let itm in visibleSpecialties.specialties) {
      delete visibleSpecialties.specialties[itm].show;
    }
    delete visibleSpecialties.uuid;
    delete visibleSpecialties.updated_date;
    delete visibleSpecialties.created_by;
    visibleSpecialties.updated_by = uuid;
    if (
      visibleSpecialties.list_status === "REQUEST FOR REVIEW" &&
      msg !== "Are you sure you want to Submit Review ?"
    ) {
      visibleSpecialties.reviewerlist = approverUserList;
      visibleSpecialties.reviewsubmitted = [];
      visibleSpecialties.approverlist = visibleSpecialties.approverlist ? visibleSpecialties.approverlist : [];
    }
    if (visibleSpecialties.list_status === "REQUEST FOR APPROVAL") {
      visibleSpecialties.approverlist = approverUserList;
    }
    dispatch(
      UPDATE_SPECIALITYLIST({
        updatedList: visibleSpecialties,
        UUID: updatedObjID,
      })
    );
    addToast(infoToastConfig(toastMsg, "success"));
  };
  const checkEditTitle = () => {
    if (editTitle || editBrandIndication) {
      return "";
    }
    if (
      userRole === "spclty_hub_mlr_approver" &&
      visibleSpecialties.list_status === "REQUEST FOR APPROVAL" &&
      !visibleSpecialties.list_quarter
    ) {
      return "green-btn";
    }
    if (
      userRole === "spclty_hub_incentivized_approver" &&
      visibleSpecialties.list_quarter &&
      visibleSpecialties.list_status === "REQUEST FOR APPROVAL"
    ) {
      return "green-btn";
    }
    return "blue-btn";
  };
  const checkIncentivisedList = (slist, loginRole) => {
    if (
      slist &&
      slist.list_quarter &&
      loginRole === "spclty_hub_incentivized_approver"
    ) {
      return true;
    }
    if (
      slist &&
      !slist.list_quarter &&
      loginRole === "spclty_hub_mlr_approver"
    ) {
      return true;
    }

    return false;
  };
  const selcheckBoxClicked = (event, item) => {
    const tempData = JSON.parse(JSON.stringify(visibleSpecialties));
    const index = getRowIndex(item);

    let chkbx = JSON.parse(JSON.stringify(selectedValues));
    if (event.target.checked === true) {
      tempData.specialties[index].selectedSpecialty = true;
      chkbx.push(index);
    } else {
      tempData.specialties[index].selectedSpecialty = false;
      chkbx = selectedValues.filter(function (idx) {
        return idx !== index;
      });
    }

    setselectedValues(chkbx);
    setVisibleSpecialties(tempData);
  };
  const incvcheckBoxClicked = (event, item) => {
    const tempData = JSON.parse(JSON.stringify(visibleSpecialties));
    const listIndex = getRowIndex(item);
    const toggleSpeciality = !!event.target.checked;
    if (!toggleSpeciality) {
      tempData.specialties[listIndex].responsibility_code = "";
    }
    tempData.specialties[listIndex].incentivized = toggleSpeciality;
    setVisibleSpecialties(tempData);
    if (!tempData.specialties[listIndex].selectedSpecialty) {
      // this.showSearchResponsibilityModal(
      //   visibleSpecialties.specialties[listIndex],
      //   listIndex
      // );
      showSearchResponsibilityModal(tempData.specialties[listIndex]);
    }
  };
  const confirmDelete = (isConfirmDel) => {
    const tempData = JSON.parse(JSON.stringify(visibleSpecialties));
    if (
      isConfirmDel &&
      isConfirmDel === "Yes" &&
      deleteMultipleSpecialties === false
    ) {
      tempData.specialties = tempData.specialties.filter(
        (el, index) => index !== delSpeclIndex
      );
      setVisibleSpecialties(tempData);
      dispatch(SET_SPECIALITY_LIST(tempData));
      const tempselcvalues = [];
      for (let i in tempData.specialties) {
        if (tempData.specialties[i].selectedSpecialty === true) {
          tempselcvalues.push(i * 1);
        }
      }

      setselectedValues(tempselcvalues);
      setIsDelModalOpen(false);
      return;
    }
    if (
      isConfirmDel &&
      isConfirmDel === "Yes" &&
      selectedValues.length > 0 &&
      deleteMultipleSpecialties === true
    ) {
      let selectedSpecialtyCodes = [];
      for (let i in selectedValues) {
        selectedSpecialtyCodes.push(
          visibleSpecialties.specialties[selectedValues[i]].specialty_code
        );
      }

      for (let i = 0; i < selectedSpecialtyCodes.length; i++) {
        tempData.specialties = tempData.specialties.filter(
          (obj) => obj.specialty_code !== selectedSpecialtyCodes[i]
        );
      }
      setVisibleSpecialties(tempData);
      dispatch(SET_SPECIALITY_LIST(tempData));
      setselectedValues([]);
      setIsDelModalOpen(false);
      return;
    }
    // this.$ldsToast.toast(
    //     'Please Select Specialties Which Needs to be Deleted.',
    //     { variant: 'error' },
    //   );
  };
  const updateSelectedResponsibility = (selectedResponsibility) => {
    // eslint-disable-next-line no-multi-assign
    visibleSpecialties.specialties[updateResCodeIndex].responsibility_code =
      selectedResponsibility;
  };

  const allowToEditTitleAndMetaData = () => {
    // not allowing any role for archived lists
    if (specialtyList.list_status === "ARCHIVE") {
      return false;
    }

    // now allowing viewer role any list
    if (sessionStorage.getItem("role") === "spclty_hub_viewer") {
      return false;
    }

    // allowing super admin for all other lists
    if (checkListStatusSuperAdmin()) {
      return true;
    }

    // now allowing other roles for approved lists
    if (specialtyList.list_status === "APPROVED") {
      return false;
    }

    // allowing HCP SF Marketer or Incentivized Approver only if list is incentivized
    if (checkRoleSFMarketer()) {
      if (specialtyList.list_quarter) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  };

  const updateCurrentPage = (page) => {
    setCurrentPage(page);
    const start = (page - 1) * pageSize;
    setStart(start);
    setEnd(start + pageSize);
  };
  const checkdisabled = () => {
    if (
      editTitle ||
      editBrandIndication ||
      (specialtyList?.list_status === "REQUEST FOR APPROVAL" &&
        specialtyList?.approverlist?.length !== 0 &&
        !specialtyList?.approverlist?.some((item) =>
          useruuids.includes(item)
        )) ||
      (specialtyList?.list_status === "REQUEST FOR REVIEW" &&
        specialtyList?.reviewerlist?.length !== 0 &&
        !specialtyList?.reviewerlist?.some((item) => useruuids.includes(item)))
    ) {
      return true;
    }
    return false;
  };
  return (
    <>
      <LdsCard className="details-card mt-4">
        <LdsCard.Body>
          {CheckViewerRole() && (
            <div>
              <LdsButton onClick={downloadcsv} classes="action compact">
                Export List Data
              </LdsButton>
            </div>
          )}
          <>
            <div className="created-txt mt-2">
              {convertDateFormat(specialtyList.updated_date)} | Updated by{" "}
              {getCreateUpdateBy(specialtyList.updated_by)}
            </div>
            {!editTitle && (
              <div className="content-block details-heading">
                <div className="row speciality-title">
                  <div className=" col col-md-auto">
                    <h3>{specialtyList.list_title}</h3>
                  </div>
                  {allowToEditTitleAndMetaData() && (
                    <div className="col col-md-auto">
                      <LdsButton
                        linkButton
                        className="edit-title-lnk"
                        onClick={editSpecialityTitle}
                      >
                        Edit Title
                      </LdsButton>
                    </div>
                  )}
                  <div className="col col-md-auto">
                    <div>
                      <span>
                        <LdsBadge className={`ml-4 ${badgeColourClass()}`}>
                          {specialtyList.list_status}
                        </LdsBadge>
                      </span>
                      <span>
                        <LdsBadge className={["ml-4", "dept-badges"]}>
                          {specialtyList.department_field === "RETAIL"
                            ? "COMMERCIAL"
                            : "MEDICAL"}
                        </LdsBadge>
                      </span>
                      <span>
                        {specialtyList.list_quarter !== "" && (
                          <LdsBadge className={["ml-4", "light-green-btn"]}>
                            SALESFORCE
                          </LdsBadge>
                        )}
                      </span>
                      <span>
                        {specialtyList.list_quarter === "" && (
                          <LdsBadge className={["ml-4", "grey-clr"]}>
                            MLR
                          </LdsBadge>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {editTitle && (
              <div className="mt-3">
                <div className="row speciality-title">
                  <div className="col col-12 col-md-6">
                    <LdsTextField
                      id="editspecialityListField"
                      name="editspecialityListField"
                      value={editTitleValue}
                      onChange={(event) =>
                        seteditTitleValue(event.target.value)
                      }
                      required
                      label="Specialty List Title"
                    />
                  </div>
                  <div className="savecl">
                    <LdsButton
                      disabled={isSaveDisabled}
                      onClick={saveSpecialityTitle}
                      className={[
                        "button-icon-right ml-4 mt-4",
                        isSaveDisabled ? "" : "blue-btn",
                      ]}
                      type="submit"
                    >
                      Save
                    </LdsButton>
                    <LdsButton
                      onClick={closeEditTitle}
                      className={["mrgn-left", "blue-bdr-btn"]}
                    >
                      Cancel
                    </LdsButton>
                  </div>
                </div>
              </div>
            )}
            {!editBrandIndication && (
              <div className="mrgn-tp-6 details-heading">
                <div className="row speciality-title">
                  <div className="col col-md-auto">
                    <h4 className="brand-indictn-hdng">
                      {specialtyList.list_quarter && (
                        <span> {specialtyList.list_quarter} -</span>
                      )}{" "}
                      {specialtyList.brand}{" "}
                      {specialtyList.indication && (
                        <span> - {specialtyList.indication}</span>
                      )}
                    </h4>
                  </div>
                  {allowToEditTitleAndMetaData() && (
                    <div className="col col-md-auto">
                      <LdsButton
                        linkButton
                        className="edit-title-lnk"
                        onClick={editBrandIndicationTitle}
                      >
                        Edit Meta Data
                      </LdsButton>
                    </div>
                  )}
                </div>
              </div>
            )}
            {editBrandIndication && (
              <EditBrandIndication
                brand_value={specialtyList.brand}
                indication_value={specialtyList.indication}
                qtr_value={specialtyList.list_quarter}
                list_id={specialtyList.uuid}
                department_value={specialtyList.department_field}
                closeBrandIndicationEdit={closeBrandIndicationEdit}
                saveMetaData={saveMetaData}
              ></EditBrandIndication>
            )}
            {!checkSpecialtyListLen(visibleSpecialties) && (
              <p className="mt-3"> No Record Found</p>
            )}
            {checkSpecialtyListLen(visibleSpecialties) && (
              <div className="mt-3">
                <div className="row">
                  <div className="col col-6">
                    <LdsSearch
                      id="searchDisabled"
                      className="mt-4 pl-1"
                      name="search_keyword"
                      onChange={setsearchText}
                      value={searchText}
                      onSubmit={filterSpecialityCodeList}
                    />
                  </div>
                  <div className="col col-6">
                    <div className="AddRespBtn">
                      {showAddRespButton() && (
                        <span>
                          <LdsButton
                            className="button-icon-left blue-btn"
                            onClick={() => {
                              showaddMultiResponsibilitiesModal("add");
                            }}
                          >
                            Add Responsibility
                          </LdsButton>
                        </span>
                      )}
                      {showAddRespButton() && (
                        <span>
                          <LdsButton
                            className="button-icon-left blue-btn mx-2"
                            onClick={() => {
                              showaddMultiResponsibilitiesModal("remove");
                            }}
                          >
                            Remove Responsibility
                          </LdsButton>
                        </span>
                      )}
                      {showDeleteButton() && (
                        <span>
                          <LdsButton
                            className="button-icon-left blue-btn"
                            onClick={deleteMultiple}
                          >
                            Delete
                          </LdsButton>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <LdsTable
                  id="speciality-list-table"
                  flat
                  sortable
                  className="stl-table-list"
                  layoutClass="layout-auto"
                >
                  <thead>
                    <tr className="align-left">
                      <th style={{ width: "12ch", verticalAlign: "middle" }}>
                        <LdsLink
                          onClick={() =>
                            selectAllSpecialties(selectOrUnselect())
                          }
                        >
                          {selectOrUnselect()}
                        </LdsLink>
                      </th>
                      <SortableHeader
                        headAlign={"align-left b"}
                        header={getTableColumn()}
                        rowData={visibleSpecialties.specialties}
                        rowKeyName={[
                          "specialty_code",
                          "specialty_description",
                          "incentivized",
                          "responsibility_code",
                        ]}
                        sortedRowData={(data) => {
                          const tempVisibleSpecialties = JSON.parse(
                            JSON.stringify(visibleSpecialties)
                          );
                          tempVisibleSpecialties.specialties = data;
                          setVisibleSpecialties(tempVisibleSpecialties);
                        }}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {visibleSpecialties.specialties &&
                      visibleSpecialties.specialties
                        .filter((specialty) => specialty.show)
                        .map(
                          (aTable, index) =>
                            index >= start &&
                            index < end && (
                              <tr
                                id={aTable.specialty_code}
                                key={aTable.specialty_code}
                                className="align-left"
                              >
                                <td>
                                  <LdsCheckbox
                                    id={"selected-" + index}
                                    required={false}
                                    name={"selected-" + index}
                                    disabled={!checkListStatus()}
                                    checked={aTable.selectedSpecialty === true}
                                    value={aTable.specialty_code}
                                    onChange={(event) =>
                                      selcheckBoxClicked(event, aTable)
                                    }
                                  />
                                </td>
                                <td>{aTable.specialty_code}</td>
                                <td>{aTable.specialty_description}</td>
                                {!checkOmniOrHCPOrMLRRole() && (
                                  <td>
                                    <LdsCheckbox
                                      id={"incentivized-" + index}
                                      required={false}
                                      name={"incentivized-" + index}
                                      disabled={!checkListStatus()}
                                      checked={aTable.incentivized === true}
                                      value={aTable.specialty_code}
                                      onChange={(event) =>
                                        incvcheckBoxClicked(event, aTable)
                                      }
                                    />
                                    {aTable.incentivized ? "Yes" : "No"}
                                  </td>
                                )}
                                {!checkOmniOrHCPOrMLRRole() && (
                                  <td>{respCodeContent(aTable)}</td>
                                )}
                                {!checkOmniOrHCPOrMLRRole() && (
                                  <td>
                                    {checkListStatus() &&
                                      aTable.incentivized && (
                                        <LdsButton
                                          linkButton
                                          onClick={() =>
                                            showSearchResponsibilityModal(
                                              aTable
                                            )
                                          }
                                        >
                                          Select responsibility
                                        </LdsButton>
                                      )}
                                  </td>
                                )}
                                <td className="i-action delete-sp">
                                  {checkListStatus() &&
                                    !checkRoleSFMarketer() && (
                                      <span
                                        onClick={() => {
                                          deleteSpecialityList(aTable);
                                        }}
                                      >
                                        <LdsIcon
                                          description="Delete the speciality list.."
                                          label="Delete Speciality list"
                                          name="TrashSimpleFill"
                                          inline
                                        />
                                      </span>
                                    )}
                                </td>
                              </tr>
                            )
                        )}
                  </tbody>
                </LdsTable>
                <LdsPagination
                  currentPage={currentPage}
                  setCurrentPage={updateCurrentPage}
                  totalPages={totalPages}
                  className="mt-4"
                />
              </div>
            )}
          </>
        </LdsCard.Body>
        <LdsCard.Footer>
        {specialtyList.reviewerlist &&   <div><br></br>
            <span><b>Reviewer List:</b></span>
            <ul>{specialtyList.reviewerlist.map((value,index)=>{return <li style={{"list-style-type": "none"}}><span>&nbsp;<b style={{color: specialtyList.reviewsubmitted.includes(value) ?  "#31855b" : null}}>{userTableList.find((elm)=> elm.uuid === value)?.email_id}</b>&nbsp;</span></li>})}</ul>
          </div>}
          {specialtyList.approverlist &&   <div><br></br>
            <span><b>Approver List:</b></span>
            <ul>{specialtyList.approverlist.map((value,index)=>{return <li style={{"list-style-type": "none"}}><span>&nbsp;<b>{userTableList.find((elm)=> elm.uuid === value)?.email_id}</b>&nbsp;</span></li>})}</ul>
          </div>}
        </LdsCard.Footer>
      </LdsCard>
      <div className="row">
        <div className="col">
          {checkListStatus() && (
            <div className="savecl">
              <LdsButton
                className={[
                  "button-icon-right",
                  editTitle || editBrandIndication ? "" : "blue-bdr-btn",
                ]}
                disabled={
                  editTitle ||
                  editBrandIndication ||
                  userRole === "spclty_hub_mlr_reviewer" ||
                  userRole === "spclty_hub_mlr_approver"
                }
                onClick={DraftSpecialityDetails}
              >
                Save Draft
              </LdsButton>
              <span className="ml-4">
                <LdsButton
                  className={["button-icon-right mt-4", checkEditTitle()]}
                  disabled={checkdisabled()}
                  onClick={confirmSubmission}
                >
                  {checkIncentivisedList(specialtyList || "", userRole) &&
                  specialtyList.list_status === "REQUEST FOR APPROVAL"
                    ? "Approve"
                    : "Submit"}
                </LdsButton>
              </span>
            </div>
          )}
          <p className="button-icon-right margin-saved">
            Created by {getCreateUpdateBy(specialtyList.created_by || "")} on{" "}
            {convertDateFormat(specialtyList.created_date || "")}
          </p>
        </div>
        <ListApprovedModal
          isListAppModalOpen={isListAppModalOpen}
          setListAppModalOpen={setListAppModalOpen}
          msg={msg}
          setMsg={setMsg}
          updateSpecialityDetails={updateSpecialityDetails}
          addedSpecialtyCodes={addedSpecialtyCodes}
          uncommonRespCodes={uncommonRespCodes}
          delSpecialtyCodes={delSpecialtyCodes}
          department={specialtyList.department_field}
        ></ListApprovedModal>
      </div>
      <SearchResponsibilityModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        responsibilitylist={responsibilityList.map((itm) => ({
          ...itm,
          show: true,
        }))}
        specialtylist={visibleSpecialties}
        indexlst={updateResCodeIndex}
        updateSelectedResponsibility={updateSelectedResponsibility}
      ></SearchResponsibilityModal>
      <AddMultiRespModal
        isRespModalOpen={isRespModalOpen}
        setIsRespModalOpen={setIsRespModalOpen}
        updateMultiSelectedResponsibility={updateMultiSelectedResponsibility}
        selectedValues={selectedValues}
        respmode={respmode}
      ></AddMultiRespModal>
      <ConfirmDeleteModal
        isDelModalOpen={isDelModalOpen}
        setIsDelModalOpen={setIsDelModalOpen}
        confirmDelete={confirmDelete}
      ></ConfirmDeleteModal>
    </>
  );
};

export default OverviewTable;
