import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  LdsButton,
  LdsModal,
  LdsTable,
  LdsPagination,
  LdsSearch,
  LdsCheckbox,
} from "@elilillyco/ux-lds-react";
import "./../../assets/scss/details/addRespModal.scss";
import SortableHeader from "../shared/SortableHeader";

const SearchResponsibilityModal = ({
  isModalOpen,
  setIsModalOpen,
  specialtylist,
  responsibilitylist,
  updateSelectedResponsibility,
}) => {
  const { respCodeIndex } = useSelector(({ details }) => details);
  const tableHead = ["Responsibility Code", "Description"];
  const [allresponsibilitylist, setallresponsibilitylist] = useState(
    JSON.parse(JSON.stringify(responsibilitylist))
  );
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  let totalPg = Math.ceil(
    allresponsibilitylist.filter((itm) => itm.show).length / itemsPerPage
  );
  let indexOfLastItem = currentPage * itemsPerPage;
  let indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [selcuuid, setselcuuid] = useState([]);

  useEffect(() => {
    if (isModalOpen) {
      setCurrentPage(1);
      let selectedSpecialty = [];
      let indexLst = [];

      indexLst =
        specialtylist.specialties[
          respCodeIndex.payload
        ].responsibility_code.split(",");
      for (let i = 0; i < indexLst.length; i++) {
        for (let j = 0; j < responsibilitylist.length; j++) {
          if (indexLst[i].trim() === responsibilitylist[j].salesforce_code) {
            selectedSpecialty.push(responsibilitylist[j].uuid);
          }
        }
      }
      let tempresp = JSON.parse(JSON.stringify(responsibilitylist));
      for (let i = 0; i < tempresp.length; i += 1) {
        if (selectedSpecialty.includes(tempresp[i].uuid)) {
          tempresp[i]["isRespSelected"] = true;
        }
      }
      setallresponsibilitylist(tempresp);
      setselcuuid(selectedSpecialty);
    } else {
      setSearchTerm("");
    }
  }, [isModalOpen, respCodeIndex]);
  useEffect(() => {
    const temp = JSON.parse(JSON.stringify(allresponsibilitylist));
    let filterdata = temp.map((obj) => {
      if (
        obj.salesforce_description
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        obj.salesforce_code.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return { ...obj, show: true };
      } else {
        return { ...obj, show: false };
      }
    });
    setallresponsibilitylist(filterdata);
  }, [searchTerm]);

  const submitSearch = () => {
    const temp = JSON.parse(JSON.stringify(allresponsibilitylist));
    let filterdata = temp.map((obj) => {
      if (
        obj.salesforce_description
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        obj.salesforce_code.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return { ...obj, show: true };
      } else {
        return { ...obj, show: false };
      }
    });
    setallresponsibilitylist(filterdata);
  };
  const checkBoxClicked = (event, UUID) => {
    const tempData = JSON.parse(JSON.stringify(allresponsibilitylist));
    for (let i = 0; i < tempData.length; i++) {
      if (UUID === tempData[i].uuid) {
        tempData[i].isRespSelected = event.target.checked;
      }
    }
    setallresponsibilitylist(tempData);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleAddForm = () => {
    const filterdata = allresponsibilitylist
      .filter((obj) => obj.isRespSelected === true)
      .map((obj) => obj.salesforce_code)
      .join(",");
    updateSelectedResponsibility(filterdata);
    setIsModalOpen(false);
  };

  return (
    <>
      <LdsModal
        modalId="searchResponsibilityModal"
        open={isModalOpen}
        setModalOpen={setIsModalOpen}
        heading="Select Responsibility Code"
      >
        <div className="col-12 col-md-6">
          <strong>Search Responsibility Code or Description </strong>
          <LdsSearch
            id={"searchResponsibility"}
            onChange={setSearchTerm}
            value={searchTerm}
            onSubmit={submitSearch}
          />
        </div>

        {allresponsibilitylist.filter((itm) => itm.show).length <= 0 ? (
          <p>No Record Found</p>
        ) : (
          <div>
            <LdsTable id="specialities-table" flat>
              <thead>
                <tr>
                  <SortableHeader
                    headAlign={"align-left b"}
                    header={tableHead}
                    rowData={allresponsibilitylist}
                    rowKeyName={["salesforce_code", "salesforce_description"]}
                    sortedRowData={setallresponsibilitylist}
                  />
                </tr>
              </thead>
              <tbody>
                {allresponsibilitylist
                  .filter((itm) => itm.show)
                  .map(
                    (itm, index) =>
                      index >= indexOfFirstItem &&
                      index < indexOfLastItem && (
                        <tr>
                          <td>
                            <LdsCheckbox
                              required={false}
                              value={itm.uuid}
                              name={"Responsibility-checkbox-" + itm.uuid}
                              id={"Responsibility-checkbox-" + itm.uuid}
                              onChange={(event) =>
                                checkBoxClicked(event, itm.uuid)
                              }
                              checked={itm.isRespSelected}
                            ></LdsCheckbox>
                            {itm.salesforce_code}
                          </td>
                          <td>{itm.salesforce_description}</td>
                        </tr>
                      )
                  )}
              </tbody>
            </LdsTable>
            <div className="row">
              <div className="col">
                <LdsPagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPg}
                  className="mt-4"
                />
              </div>
            </div>
          </div>
        )}
        <div className="floatRight">
          <LdsButton
            iconPosition="before"
            classes="button-icon-left blue-bdr-btn"
            onClick={closeModal}
          >
            Cancel
          </LdsButton>
          <span className="buttonMargin">
            <LdsButton
              iconPosition="before"
              classes="button-icon-left blue-btn"
              onClick={handleAddForm}
            >
              Select
            </LdsButton>
          </span>
        </div>
      </LdsModal>
    </>
  );
};
export default SearchResponsibilityModal;
