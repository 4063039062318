import ctx from "../../plugins/axios";

/**
 * Update the list status.
 *
 * @returns {Promise<*>}
 */
const updateListStatus = async (listObj) => {
  const id = listObj.deleteDraftID || listObj.uuid;
  delete listObj["uuid"];
  return await ctx.put(`/specialty_list/${id}`, listObj);
};

export default updateListStatus;
