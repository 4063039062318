import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSpecialityList,
  updateSpecialityList,
  getCommentList,
  createComment,
  getResponsibilityList,
  getAddSpecialityList,
  getUserListForComment,
  putComments,
} from "../api/details/index";

const initialState = {
  specialtyList: {},
  comments: [],
  //responsibilityList contains all the responsibilities which are present in Ad Responsibility Modal
  responsibilityList: [],
  addspecialtyList: [],
  //selected will contain responsibilities which are selected in Add Responsibility modal
  selectedResponsibilties: [],
  commentUserList: [],
  loading: true,
  respCodeIndex: "",
  error: false,
  selected: [],
  commentLoading: true,
};

const reducers = {
  SET_SPECIALITYLIST(state, specialtyList) {
    // console.log('in SET_SPECIALITYLIST');

    specialtyList.payload.specialties.forEach((element) => {
      if (element && !element.incentivized) {
        element.incentivized = "";
      }
      if (element && !element.responsibility_code) {
        element.responsibility_code = "";
      }
    });
    // console.log('payload:', specialtyList);
    state.specialtyList = specialtyList.payload;
    // console.log(state.specialtyList);
  },
  SET_SPECIALITY_LIST(state, specialtyList) {
    state.specialtyList = specialtyList.payload;
  },
  SET_SELECTED_SPECIALITY(state, list) {
    state.selected = list.payload;
  },
  SET_SELECTED_RESPONSIBILITY(state, list) {
    state.selectedResponsibilties = list.payload;
  },
  SET_COMMENTLIST(state, comments) {
    //console.log('in SET_COMMENTLIST');
    state.comments = comments;
  },
  SET_RESPONSIBILITY_LIST(state, responsibilityList) {
    state.responsibilityList = responsibilityList.payload;
  },
  SET_ADD_SPECIALITYLIST(state, addspecialtyList) {
    state.addspecialtyList = addspecialtyList.payload;
  },
  SET_USERCOMMENTLIST(state, commentUserList) {
    //console.log('in SET_USERCOMMENTLIST');
    state.commentUserList = commentUserList;
  },
  SET_RESP_INDEX(state, index) {
    state.respCodeIndex = index;
  },
};

const FETCH_OVERVIEW_TABLE_DATA = createAsyncThunk(
  "details/FETCH_OVERVIEW_TABLE_DATA",
  async (DetailsUUID) => {
    const data = await Promise.all([
      getSpecialityList(DetailsUUID),
      getResponsibilityList(),
      getAddSpecialityList(),
    ]);
    //console.log('actions');
    return { data };
  }
);
const FETCH_COMMENTS_DATA = createAsyncThunk(
  "details/FETCH_COMMENTS_DATA",
  async (DetailsUUID) => {
    const data = await Promise.all([
      getCommentList(DetailsUUID),
      getUserListForComment(),
    ]);
    //console.log('actions');
    return { data };
  }
);

const FETCH_SPECIALITYLIST = createAsyncThunk(
  "details/FETCH_SPECIALITYLIST",
  async (DetailsUUID) => {
    const { data, status } = await getSpecialityList(DetailsUUID);
    //console.log('actions');
    return { data, status };
  }
);

const FETCH_SPECIALITYLIST_FULFILLED = (state, payload) => {
  //console.log('fullfilled in FETCH_SPECIALITYLIST');
  const { data: specialityList, status } = payload;
  if (status !== 200) {
    //console.log('err');
  } else {
    // using optional chaining to check specialty list array exist or not
    if (!specialityList.data?.specialties) {
      specialityList.data.specialties = [];
    }
    specialityList.data.specialties.forEach((v) => {
      v.selectedSpecialty = false;
      v.show = true;
    });

    // detailsSlice.caseReducers.SET_SPECIALITYLIST(
    //   state,
    //   specialityList.data || {}
    // );
    state.specialtyList = specialityList.data;
  }
};

const UPDATE_SPECIALITYLIST = createAsyncThunk(
  "details/UPDATE_SPECIALITYLIST",
  async (updatedDetails, { dispatch }) => {
    const { data, status } = await updateSpecialityList(updatedDetails);
    if (status !== 200) {
      //console.log('err', specialityList);
    } else {
      // const dispatch = useDispatch();
      dispatch(FETCH_SPECIALITYLIST(updatedDetails.UUID));
      // context.dispatch('FETCH_SPECIALITYLIST', updatedDetails.UUID);
    }

    //console.log('actions');
    return { data, status, updatedDetails };
  }
);

const FETCH_COMMENTLIST = createAsyncThunk(
  "details/FETCH_COMMENTLIST",
  async (DetailsUUID) => {
    const { data, status } = await getCommentList(DetailsUUID);
    //console.log('actions');
    return { data, status };
  }
);

const FETCH_COMMENTLIST_FULFILLED = (state, payload) => {
  //console.log('fullfilled in FETCH_COMMENTLIST');
  const { data: commentList, status } = payload;
  if (status !== 200) {
    //console.log('err');
  } else {
    detailsSlice.caseReducers.SET_COMMENTLIST(state, commentList.data || []);
  }
};

const CREATE_COMMENT = createAsyncThunk(
  "details/CREATE_COMMENT",
  async (commentObj, { dispatch }) => {
    const { status } = await createComment(commentObj);
    let dataFetching = true;
    if (status === 200) {
      dispatch(FETCH_COMMENTLIST(commentObj.list_uuid));
    } else {
      dataFetching = false;
    }
    return dataFetching;
  }
);

const FETCH_RESPONSIBILITY_LIST = createAsyncThunk(
  "details/FETCH_RESPONSIBILITY_LIST",
  async () => {
    const { data, status } = await getResponsibilityList();
    //console.log('actions');
    return { data, status };
  }
);

const FETCH_RESPONSIBILITY_LIST_FULFILLED = (state, payload) => {
  //console.log('fullfilled in FETCH_RESPONSIBILITY_LIST');
  const { data: responsibilities, status } = payload;
  if (status !== 200) {
    //console.log('err', responsibilities);
  } else {
    responsibilities.data.forEach((v) => {
      v.isRespSelected = false;
    });
    state.responsibilityList = responsibilities.data || [];
  }
};

const FETCH_ADD_SPECIALTIES_LIST = createAsyncThunk(
  "details/FETCH_ADD_SPECIALTIES_LIST",
  async (searchKeyword) => {
    const { data, status } = await getAddSpecialityList(searchKeyword);
    //console.log('actions');
    return { data, status };
  }
);

const FETCH_ADD_SPECIALTIES_LIST_FULFILLED = (state, payload) => {
  // console.log('fullfilled in FETCH_ADD_SPECIALTIES_LIST');
  const { data: addSpecialtyList, status } = payload;
  if (status !== 200) {
    // console.log('err', addSpecialtyList);
  } else {
    addSpecialtyList.data.forEach((itm) => {
      itm.isChecked = false;
    });
    state.addspecialtyList = addSpecialtyList.data || [];
  }
};

// UPDATE_RESP_CODE_INDEX

const FETCH_USER_LIST = createAsyncThunk(
  "details/FETCH_USER_LIST",
  async () => {
    const { data, status } = await getUserListForComment();
    //console.log('actions');
    return { data, status };
  }
);

const FETCH_USER_LIST_FULFILLED = (state, payload) => {
  //console.log('fullfilled in FETCH_USER_LIST');
  const { data: commentList, status } = payload;
  if (status !== 200) {
    //console.log('err', commentList);
  } else {
    detailsSlice.caseReducers.SET_USERCOMMENTLIST(
      state,
      commentList.data || []
    );
  }
};

const UPDATE_COMMENTS = createAsyncThunk(
  "details/UPDATE_COMMENTS",
  async (commentObj, { dispatch }) => {
    const { status, data } = await putComments(commentObj);
    return { status, data };
  }
);

const detailsSlice = createSlice({
  name: "details",
  initialState,
  reducers,
  extraReducers(builder) {
    builder.addCase(FETCH_OVERVIEW_TABLE_DATA.pending, (state, action) => {
      state.loading = true;
      //console.log('pending in FETCH_OVERVIEW_TABLE_DATA');
    });
    builder.addCase(
      FETCH_OVERVIEW_TABLE_DATA.fulfilled,
      (state, { payload }) => {
        const { data } = payload;
        FETCH_SPECIALITYLIST_FULFILLED(state, data[0]);
        FETCH_RESPONSIBILITY_LIST_FULFILLED(state, data[1]);
        FETCH_ADD_SPECIALTIES_LIST_FULFILLED(state, data[2]);
        state.loading = false;
        //console.log('fulfilled in FETCH_OVERVIEW_TABLE_DATA');
      }
    );
    builder.addCase(FETCH_OVERVIEW_TABLE_DATA.rejected, (state, action) => {
      state.loading = false;
      //console.log('rejected in FETCH_OVERVIEW_TABLE_DATA');
    });

    builder.addCase(FETCH_COMMENTS_DATA.pending, (state, action) => {
      state.commentLoading = true;
      //console.log('pending in FETCH_COMMENTS_DATA');
    });
    builder.addCase(FETCH_COMMENTS_DATA.fulfilled, (state, { payload }) => {
      const { data } = payload;
      FETCH_COMMENTLIST_FULFILLED(state, data[0]);
      FETCH_USER_LIST_FULFILLED(state, data[1]);
      state.commentLoading = false;
      //console.log('fulfilled in FETCH_COMMENTS_DATA');
    });
    builder.addCase(FETCH_COMMENTS_DATA.rejected, (state, action) => {
      state.commentLoading = false;
      //console.log('rejected in FETCH_COMMENTS_DATA');
    });

    builder.addCase(FETCH_SPECIALITYLIST.fulfilled, (state, { payload }) => {
      FETCH_SPECIALITYLIST_FULFILLED(state, payload);

      state.loading = false;
    });
    builder.addCase(FETCH_SPECIALITYLIST.pending, (state, action) => {
      state.loading = true;
      //console.log('pending in FETCH_SPECIALITYLIST');
    });
    builder.addCase(FETCH_SPECIALITYLIST.rejected, (state, action) => {
      state.loading = false;
      //console.log('err in FETCH_SPECIALITYLIST');
    });
    builder.addCase(UPDATE_SPECIALITYLIST.fulfilled, (state, { payload }) => {
      //console.log('fullfilled in UPDATE_SPECIALITYLIST');
      const { data: specialityList, status, updatedDetails } = payload;
      if (status !== 200) {
        //console.log('err', specialityList);
      } else {
        // const dispatch = useDispatch();
        FETCH_SPECIALITYLIST(updatedDetails.UUID);
        // context.dispatch('FETCH_SPECIALITYLIST', updatedDetails.UUID);
      }
      state.loading = false;
    });
    builder.addCase(UPDATE_SPECIALITYLIST.pending, (state, action) => {
      state.loading = true;
      //console.log('pending in UPDATE_SPECIALITYLIST');
    });
    builder.addCase(UPDATE_SPECIALITYLIST.rejected, (state, action) => {
      state.loading = false;
      //console.log('err in UPDATE_SPECIALITYLIST');
    });
    builder.addCase(FETCH_COMMENTLIST.fulfilled, (state, { payload }) => {
      FETCH_COMMENTLIST_FULFILLED(state, payload);
      state.commentLoading = false;
    });
    builder.addCase(FETCH_COMMENTLIST.pending, (state, action) => {
      state.commentLoading = true;
      //console.log('pending in FETCH_COMMENTLIST');
    });
    builder.addCase(FETCH_COMMENTLIST.rejected, (state, action) => {
      state.commentLoading = false;
      //console.log('err in FETCH_COMMENTLIST');
    });
    builder.addCase(CREATE_COMMENT.fulfilled, (state, { payload }) => {
      if (!payload) {
        state.commentLoading = false;
      }
    });
    builder.addCase(CREATE_COMMENT.pending, (state, action) => {
      state.commentLoading = true;
      //console.log('pending in CREATE_COMMENT');
    });
    builder.addCase(CREATE_COMMENT.rejected, (state, action) => {
      state.commentLoading = false;
      //console.log('err in CREATE_COMMENT');
    });
    builder.addCase(
      FETCH_RESPONSIBILITY_LIST.fulfilled,
      (state, { payload }) => {
        FETCH_RESPONSIBILITY_LIST_FULFILLED(state, payload);
        state.loading = false;
      }
    );
    builder.addCase(FETCH_RESPONSIBILITY_LIST.pending, (state, action) => {
      state.loading = true;
      //console.log('pending in FETCH_RESPONSIBILITY_LIST');
    });
    builder.addCase(FETCH_RESPONSIBILITY_LIST.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(FETCH_ADD_SPECIALTIES_LIST.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      FETCH_ADD_SPECIALTIES_LIST.fulfilled,
      (state, { payload }) => {
        FETCH_ADD_SPECIALTIES_LIST_FULFILLED(state, payload);

        state.loading = false;
      }
    );
    builder.addCase(FETCH_ADD_SPECIALTIES_LIST.rejected, (state, action) => {
      state.loading = false;
      //console.log('err in FETCH_ADD_SPECIALTIES_LIST');
    });
    builder.addCase(FETCH_USER_LIST.fulfilled, (state, { payload }) => {
      FETCH_USER_LIST_FULFILLED(state, payload);
      state.commentLoading = false;
    });
    builder.addCase(FETCH_USER_LIST.pending, (state, action) => {
      state.commentLoading = true;
      //console.log('pending in FETCH_USER_LIST');
    });
    builder.addCase(FETCH_USER_LIST.rejected, (state, action) => {
      state.commentLoading = false;
      //console.log('err in FETCH_USER_LIST');
    });
    builder.addCase(UPDATE_COMMENTS.fulfilled, (state, { payload }) => {
      const { data: commentList, status } = payload;
      if (status === 200) {
        state.comments = commentList.data;
      }
      state.commentLoading = false;
    });
    builder.addCase(UPDATE_COMMENTS.pending, (state, action) => {
      state.commentLoading = true;
      //console.log('pending in UPDATE_COMMENTS');
    });
    builder.addCase(UPDATE_COMMENTS.rejected, (state, action) => {
      state.commentLoading = false;
      //console.log('err in UPDATE_COMMENTS');
    });
  },
});
export const {
  SET_RESPONSIBILITY_LIST,
  SET_SELECTED_RESPONSIBILITY,
  SET_ADD_SPECIALITYLIST,
  SET_SELECTED_SPECIALITY,
  SET_SPECIALITYLIST,
  SET_SPECIALITY_LIST,
  SET_RESP_INDEX,
} = detailsSlice.actions;
export {
  FETCH_SPECIALITYLIST,
  UPDATE_SPECIALITYLIST,
  FETCH_COMMENTLIST,
  CREATE_COMMENT,
  FETCH_RESPONSIBILITY_LIST,
  FETCH_ADD_SPECIALTIES_LIST,
  FETCH_USER_LIST,
  UPDATE_COMMENTS,
  FETCH_OVERVIEW_TABLE_DATA,
  FETCH_COMMENTS_DATA,
};

export default detailsSlice.reducer;
