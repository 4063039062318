import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LdsButton,
  LdsModal,
  LdsTable,
  LdsPagination,
  LdsSearch,
  LdsCheckbox,
  LdsLoadingSpinner,
} from "@elilillyco/ux-lds-react";
import {
  FETCH_ADD_SPECIALTIES_LIST,
  SET_ADD_SPECIALITYLIST,
  SET_SELECTED_SPECIALITY,
  SET_SPECIALITYLIST,
  SET_SPECIALITY_LIST,
} from "../../store/details";

import "./../../assets/scss/details/addSpecialtyModal.scss";
import { json } from "react-router-dom";
import SortableHeader from "../shared/SortableHeader";
const AddSpecialtyModal = ({
  isModalOpen,
  setIsModalOpen,
  specialties,
  onDataUpdate,
  visibleSpecialties,
  setVisibleSpecialties,
}) => {
  const { addspecialtyList, loading, selected } = useSelector(
    ({ details }) => details
  );
  const dispatch = useDispatch();

  let [data, setData] = useState(addspecialtyList);
  var selectedSpecialty = JSON.parse(JSON.stringify(selected));
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPg = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    let tempData = JSON.parse(JSON.stringify(addspecialtyList));

    //below logic is to remove already present specialties on details screen from Add spec modal
    if (
      visibleSpecialties.specialties &&
      visibleSpecialties.specialties.length > 0
    ) {
      for (let i = 0; i < visibleSpecialties.specialties.length; i++) {
        tempData = tempData.filter(
          (itm) =>
            itm.description !==
            visibleSpecialties.specialties[i].specialty_description
        );
      }
    }
    if (searchTerm.trim() !== "") {
      tempData = tempData.filter(
        (obj) =>
          obj.specialty
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase()) ||
          obj.description
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase())
      );
      setCurrentPage(1);
    }

    setData(tempData);
  }, [addspecialtyList, searchTerm, visibleSpecialties]);

  //below function is triggerd when Add button is clicked
  function handleAddForm() {
    let tempData = JSON.parse(JSON.stringify(addspecialtyList));
    tempData.forEach((obj) => (obj.isChecked = false));
    dispatch(SET_ADD_SPECIALITYLIST(tempData));

    let finalSelectedList = [];
    //below logic is to remove specialties which we have been checked and added so they are not shown in Add specialty modal now.
    if (selectedSpecialty && selectedSpecialty.length > 0) {
      for (let i = 0; i < selectedSpecialty.length; i++) {
        tempData = tempData.filter((itm) => itm.uuid !== selectedSpecialty[i]);
      }
      setData(tempData);
    }
    addspecialtyList.forEach((slData) => {
      if (selected.includes(slData.uuid)) {
        finalSelectedList.push({
          uuid: slData.uuid,
          specialty_code: slData.specialty,
          specialty_description: slData.description,
          responsibility_code: "",
          incentivized: false,
          show: true,
          selectedSpecialty: false,
        });
      }
    });
    let tempSpecialtyList = JSON.parse(JSON.stringify(visibleSpecialties));
    for (let i = 0; i < finalSelectedList.length; i++) {
      tempSpecialtyList.specialties.push(finalSelectedList[i]);
    }

    dispatch(SET_SELECTED_SPECIALITY([]));
    setVisibleSpecialties(tempSpecialtyList);
    // dispatch(FETCH_ADD_SPECIALTIES_LIST());
    onDataUpdate(finalSelectedList);
    setIsModalOpen(false);
  }
  //below function is triggerd when close button is clicked
  function closeModal() {
    setIsModalOpen(false);
  }
  //below function is triggered when page no. is clicked
  function clickLink(e, value) {
    setCurrentPage(value);
  }
  //below function is triggerd when Next button is clicked in pagination component
  function clickNext() {
    setCurrentPage((prev) => {
      return prev + 1;
    });
  }
  //below function is triggerd when Prev button is clicked in pagination component
  function clickPrev() {
    setCurrentPage((prev) => {
      return prev - 1;
    });
  }
  //below function is triggerd when we submit the value entered in searchbar
  function submitSearch() {
    let tempData = JSON.parse(JSON.stringify(addspecialtyList));
    //below logic is to remove already present specialties on details screen from Add spec modal
    if (
      visibleSpecialties.specialties &&
      visibleSpecialties.specialties.length > 0
    ) {
      for (let i = 0; i < visibleSpecialties.specialties.length; i++) {
        tempData = tempData.filter(
          (itm) =>
            itm.description !==
            visibleSpecialties.specialties[i].specialty_description
        );
      }
    }

    tempData = tempData.filter(
      (obj) =>
        obj.specialty.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
        obj.description.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );

    setData(tempData);
    setCurrentPage(1);
  }
  // below function is triggered when checkbox is clicked
  function checkBoxClicked(event, UUID) {
    let tempData = JSON.parse(JSON.stringify(addspecialtyList));

    for (let i = 0; i < tempData.length; i++) {
      if (UUID === tempData[i].uuid) {
        if (event.target.checked === true) {
          tempData[i].isChecked = true;
          selectedSpecialty.push(event.target.value);
        } else {
          tempData[i].isChecked = false;
          selectedSpecialty = selectedSpecialty.filter(
            (itm) => itm !== event.target.value
          );
        }
      }
    }

    dispatch(SET_ADD_SPECIALITYLIST(tempData));
    dispatch(SET_SELECTED_SPECIALITY(selectedSpecialty));
    // setData(tempData);
  }
  const tableHead = ["Specialty Code", "Description"];

  return (
    <>
      <LdsModal
        modalId="add-spclty-modal"
        open={isModalOpen}
        setModalOpen={setIsModalOpen}
        heading="Add Specialty"
      >
        <div>
          <div className="col-12 col-md-6">
            <strong>Search Specialty </strong>
            <LdsSearch
              id={"searchSpecialty"}
              onChange={setSearchTerm}
              value={searchTerm}
              onSubmit={submitSearch}
            />
          </div>
          {loading ? (
            <LdsLoadingSpinner size={90} />
          ) : (
            <div>
              {data.length > 0 ? (
                <>
                  <table id="specialities-table" className="lds-table flat">
                    <thead>
                      <tr>
                        <SortableHeader
                          headAlign={"align-left b"}
                          header={tableHead}
                          rowData={data}
                          rowKeyName={["specialty", "description"]}
                          sortedRowData={setData}
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {data.map(
                        (itm, index) =>
                          index >= indexOfFirstItem &&
                          index < indexOfLastItem && (
                            <>
                              <tr>
                                <td>
                                  <LdsCheckbox
                                    required={false}
                                    value={itm.uuid}
                                    name={"specialty-checkbox-" + itm.uuid}
                                    id={"specialty-checkbox-" + itm.uuid}
                                    onChange={(event) =>
                                      checkBoxClicked(event, itm.uuid)
                                    }
                                    checked={itm.isChecked}
                                  ></LdsCheckbox>
                                  {itm.specialty}
                                </td>
                                <td>{itm.description}</td>
                              </tr>
                            </>
                          )
                      )}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col">
                      <LdsPagination
                        className="floatRight"
                        currentPage={currentPage}
                        onLinkClick={clickLink}
                        totalPages={totalPg}
                        onNextClick={clickNext}
                        onPrevClick={clickPrev}
                      />
                    </div>
                  </div>

                  <div className="floatRight">
                    <LdsButton
                      iconPosition="before"
                      classes="button-icon-left blue-bdr-btn"
                      onClick={closeModal}
                    >
                      Cancel
                    </LdsButton>
                    <span className="buttonMargin">
                      <LdsButton
                        iconPosition="before"
                        classes="button-icon-left blue-btn"
                        className="buttonMargin"
                        onClick={handleAddForm}
                      >
                        Add
                      </LdsButton>
                    </span>
                  </div>
                </>
              ) : (
                <p>No Record Found</p>
              )}
            </div>
          )}
        </div>
      </LdsModal>
    </>
  );
};
export default AddSpecialtyModal;
