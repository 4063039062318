import { LdsButton, LdsModal } from "@elilillyco/ux-lds-react";

const ArchiveConfirmationModal = ({ archiveList, open, setModalOpen }) => {
  return (
    <LdsModal
      modalId="DeleteBUModal"
      className="text-center"
      size="col-12 col-sm-8 col-md-8 col-lg-8"
      open={open}
      setModalOpen={setModalOpen}
    >
      <div>
        <div>
          <h2>Are you sure you want to archive the list?</h2>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="floatRight">
            <LdsButton
              classes="button-icon-right blue-bdr-btn"
              onClick={() => setModalOpen(false)}
            >
              No
            </LdsButton>
            <LdsButton
              classes="button-icon-right blue-btn ml-4 mt-4"
              type="submit"
              onClick={archiveList}
            >
              Confirm Yes
            </LdsButton>
          </div>
        </div>
      </div>
    </LdsModal>
  );
};

export default ArchiveConfirmationModal;
