import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  statusCode: 0,
};

const reducers = {
  ADD_ERROR(state, { payload }) {
    const message =
      payload.message || payload.errormessage || payload.successmessage;
    if (message) {
      state.error = message;
    }
  },
  REMOVE_ERROR(state, { payload }) {
    state.error = "";
  },
  UPDATE_ERROR_PAGE_DATA(state, { payload }) {
    state.statusCode = payload;
  },
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers,
});

export const { ADD_ERROR, REMOVE_ERROR, UPDATE_ERROR_PAGE_DATA } =
  errorSlice.actions;

export default errorSlice.reducer;
