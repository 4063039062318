import { mkConfig, generateCsv, download } from 'export-to-csv';

const exportExcelSheet = (sListDetails, filename) => {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: false,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: filename,
  };
  const csvConfig = mkConfig(options);

  const csv = generateCsv(csvConfig)(sListDetails);
  download(csvConfig)(csv);
};

export default exportExcelSheet;
