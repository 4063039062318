import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  redirect,
  useNavigate,
} from "react-router-dom";
import * as RoutePath from "../pages/index";
import TopNavBar from "../layout/TopNavBar";
import Middleware from "../middleware/middleware";
import { LdsToastProvider, LdsToast } from "@elilillyco/ux-lds-react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const RoutesDef = () => {
  return (
    <BrowserRouter>
      <Middleware>
        <LdsToastProvider maxToasts={1}>
          <div id="main">
            <Routes>
              <Route path="/" Component={() => <TopNavBar />}>
                <Route
                  path="/overview"
                  Component={() => <RoutePath.Overview />}
                />
                <Route
                  path="/approvedspecialities"
                  Component={() => <RoutePath.ApprovedSpec />}
                />
                <Route
                  path="/userprofile"
                  Component={() => <RoutePath.Userprofile />}
                />
                <Route
                  path="/details/:id"
                  Component={() => <RoutePath.Details />}
                />
                <Route
                  path="/superadmin/import"
                  Component={() => <RoutePath.Import />}
                />
                <Route path="/admin">
                  <Route
                    index
                    path="/admin/users"
                    Component={() => <RoutePath.Users />}
                  />
                  <Route
                    path="/admin/specialties"
                    Component={() => <RoutePath.Specialties />}
                  />
                  <Route
                    path="/admin/add-specialties"
                    Component={() => <RoutePath.Specialties />}
                  />
                  <Route path="/admin/BU" Component={() => <RoutePath.Bu />} />
                </Route>
                <Route
                  path="/specialties"
                  Component={() => <RoutePath.Specialties />}
                />
                <Route
                  path="/add-specialties"
                  Component={() => <RoutePath.Specialties />}
                />
                <Route
                  path="/unauthorized-access"
                  Component={() => <RoutePath.UnAuthorizedAccess />}
                />
                <Route path="/error" Component={() => <RoutePath.Error />} />
                <Route path="*" Component={() => <RoutePath.NotFound />} />
              </Route>
            </Routes>
          </div>
          <LdsToast />
        </LdsToastProvider>
      </Middleware>
    </BrowserRouter>
  );
};

export default RoutesDef;
