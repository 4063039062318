import { LdsButton } from "@elilillyco/ux-lds-react";
import { useEffect, useState } from "react";

import "./../../assets/scss/shared/sortableHeader.scss";

// mandatory fields : headAlign, header, rowData, rowKeyName, sortedRowData
// header : need to pass header names as a array
// rowData : need to pass rows data keys as an array to apply sorting.
// rowKeyName : need to pass the row data keys in a order of its display order
// ex: <tr> {entry.row_key1} </tr>
// <tr> {entry.row_key2} </tr>
// need to send row data keys. => [row_key1, row_key2]
// sortedRowData : it is a function from parent component, which contains a argument. it will be two way binding.

export default function SortableHeader({
  header,
  rowData,
  rowKeyName,
  sortedRowData,
}) {
  const [isOpacity, setIsOpacity] = useState({ index: null, direction: null });

  const resetArrows = (setState) => {
    const arrowObj = { up: false, down: false };
    // console.log(arrowObj);

    const tempArrows = [];
    for (let heading in header) {
      tempArrows.push(arrowObj);
    }
    // console.log(tempArrows);
    if (setState) {
      setIsOpacity(tempArrows);
    } else {
      return [...tempArrows];
    }
  };

  function DownArrowFunction(selectedID, heading) {
    const rowList = JSON.parse(JSON.stringify(rowData));
    let sortedData = [];
    // const selectedID = e.target.id;

    if (rowList.length === 0) {
      return;
    }

    if (heading.toLowerCase().includes("date")) {
      sortedData = rowList.sort(
        (a, b) => new Date(b[selectedID]) - new Date(a[selectedID])
      );
    } else {
      sortedData = rowList.sort((b, a) => {
        let aNo = !isNaN(+a[selectedID]);
        let bNo = !isNaN(+b[selectedID]);

        if (aNo && bNo) {
          return a[selectedID] - b[selectedID];
        } else if (aNo && !bNo) {
          return -1;
        } else if (!aNo && bNo) {
          return 1;
        } else {
          if (a[selectedID] < b[selectedID]) return -1;
          if (a[selectedID] > b[selectedID]) return 1;
          return 0;
        }
      });
    }
    sortedRowData(sortedData);
  }

  function upArrowFunction(selectedID, heading) {
    const rowList = JSON.parse(JSON.stringify(rowData));
    let sortedData = [];
    // const selectedID = e.target.id;

    if (rowList.length === 0) {
      return;
    }
    if (heading.toLowerCase().includes("date")) {
      sortedData = rowList.sort(
        (b, a) => new Date(b[selectedID]) - new Date(a[selectedID])
      );
    } else {
      sortedData = rowList.sort((b, a) => {
        let aNo = !isNaN(+a[selectedID]);
        let bNo = !isNaN(+b[selectedID]);

        if (aNo && bNo) {
          return b[selectedID] - a[selectedID];
        } else if (aNo && !bNo) {
          return 1;
        } else if (!aNo && bNo) {
          return -1;
        } else {
          if (a[selectedID] > b[selectedID]) return -1;
          if (a[selectedID] < b[selectedID]) return 1;
          return 0;
        }
      });
    }
    sortedRowData(sortedData);
  }

  const sort = (index, property, headingName) => {
    let direction = "up";
    if (isOpacity.index === index) {
      direction = isOpacity.direction === "up" ? "down" : "up";
    }
    setIsOpacity({
      index,
      direction,
    });
    if (direction === "up") {
      upArrowFunction(property, headingName);
    } else {
      DownArrowFunction(property, headingName);
    }
  };

  return (
    <>
      {header.length &&
        header.map((headingName, index) => {
          return (
            <th
              key={index}
              className="align-left headingMargin"
              style={{
                padding: "0.5rem",
                cursor: "pointer",
                userSelect: "none",
              }}
              onClick={() => sort(index, rowKeyName[index], headingName)}
            >
              {headingName.trim() && (
                <div className="d-flex align-center">
                  <div>{headingName}</div>
                  <div className="header-height">
                    <div>
                      <div
                        className={
                          isOpacity.index === index &&
                          isOpacity.direction === "up"
                            ? "opacity-button up-caret"
                            : "no-opacity-button up-caret"
                        }
                      ></div>
                    </div>
                    <div>
                      <div
                        className={
                          isOpacity.index === index &&
                          isOpacity.direction === "down"
                            ? "opacity-button down-caret"
                            : "no-opacity-button down-caret"
                        }
                      ></div>
                    </div>
                  </div>
                </div>
              )}
            </th>
          );
        })}
    </>
  );
}
