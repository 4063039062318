import ctx from "../../plugins/axios";

/**
 *  CREATE  specialty list from exisitng brand and indication or scratch
 *
 * @returns {Promise<*>}
 */
const createSpecialtyList = async (cloneObj) => {
  return await ctx.post("/specialty_list/clone", cloneObj);
};

export default createSpecialtyList;
