import { useEffect, useState } from "react";
import {
  LdsButton,
  useToastContext,
  LdsSearch,
} from "@elilillyco/ux-lds-react";
import { useDispatch, useSelector } from "react-redux";

import {
  UPDATE_USER_LIST,
  FETCH_USER_LIST,
  FETCH_BRAND_LIST,
  FETCH_INCENTIVIZED_JOB,
} from "../store/admin";

import CLoader from "../components/shared/CLoader";
import UsersTable from "../components/admin/UsersTable";
import infoToastConfig from "../components/shared/Toast";

const Userprofile = ()=>{
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(FETCH_USER_LIST());
      dispatch(FETCH_BRAND_LIST());
    }, []);

    const { loading } = useSelector(({ admin }) => admin);
    const { uuid } = useSelector(({ user }) => user.user || {});
    const {emailId} = useSelector(({user}) => user || '');

    const { addToast } = useToastContext();

    const saveUserListForm = async (obj) => {
        if (Array.isArray(obj)) {
          obj.forEach((object) => {
            delete object["isSelected"];
            delete object["isVisible"];
          });
        } else {
          delete obj.isVisible;
          delete obj.isSelected;
        }
        const updateUserDetails = {
          users: Array.isArray(obj) ? obj : [obj],
          updated_by: uuid,
        };
        return await dispatch(UPDATE_USER_LIST(updateUserDetails));
      };

    return <>  {loading && <CLoader size={90} />}
    {!loading && (
      <UsersTable
        saveUserListForm={saveUserListForm}
        searchText={emailId}
        isProfile={true}
      ></UsersTable>
    )}</>
};

export default Userprofile;