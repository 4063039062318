import ctx from "../../plugins/axios";

/**
 * Gets the Product List by look Up.
 *
 * @returns {Promise<*>}
 */
const getProductList = async (obj) => {
  return await ctx.get(
    `/product?search_keyword=${obj.val}&department_field=${obj.department}`
  );
};

export default getProductList;
