import { LdsButton, LdsIcon, LdsModal } from "@elilillyco/ux-lds-react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { FETCH_OVERVIEW, UNDO_DELETE_LIST } from "../../store/overview";

import "./../../assets/scss/overview/deleteModal.scss";

const DeleteModal = ({ isModalOpen, setIsModalOpen, showSuccessToast }) => {
  const dispatch = useDispatch();

  const { undoDraftId, selectedBrand } = useSelector(
    ({ overview }) => overview
  );
  const userId = useSelector(({ user }) => user.uuid);

  useEffect(() => {
    return () => {
      dispatch(
        FETCH_OVERVIEW({
          brand: selectedBrand,
          created_by: userId,
          user_role: sessionStorage.getItem("role"),
        })
      );
    };
  }, []);

  const undoDeletedList = async () => {
    const undoDeletedObj = {
      updated_by: userId, // WIll Add the user id of User
      active: true,
      deleteDraftID: undoDraftId,
    };
    const { meta } = await dispatch(UNDO_DELETE_LIST(undoDeletedObj));
    if (meta.requestStatus === "fulfilled") {
      setIsModalOpen(false);
      showSuccessToast();
    }
  };

  const returnToOverview = () => {
    setIsModalOpen(false);
  };

  return (
    <LdsModal
      modalId="deleteDraft"
      className="text-center"
      modalSizeClass={"col-12 col-sm-8 col-md-8 col-lg-8"}
      open={isModalOpen}
      setModalOpen={setIsModalOpen}
    >
      <div className="icon-size">
        <LdsIcon name="CheckCircleFill" className="chx-green" />
      </div>
      <h2 className="mb-0">List has been deleted</h2>
      <div>
        <LdsButton linkButton onClick={undoDeletedList}>
          Undo action
        </LdsButton>
      </div>
      <div className="btn">
        <LdsButton
          className="button-icon-left blue-btn"
          onClick={returnToOverview}
        >
          Return to Overview
        </LdsButton>
      </div>
    </LdsModal>
  );
};

export default DeleteModal;
