import { LdsIcon } from "@elilillyco/ux-lds-react";

import "./../../assets/scss/error/unAuthorizedAccess.scss";

const UnAuthorizedAccess = () => {
  return (
    <div>
      <div class="ua-margin-bottom">
        <div class="ua-align">
          <div>
            <div class="ua-margin-top">
              <LdsIcon
                name="XCircleFill"
                class="ua-icon-checkmark-filled"
                label="No Access"
              />
            </div>
            <span>
              <h2>Unauthorized Access!</h2>
              <h3>
                It seems you don't have access to the application. Please
                Contact Admin Team.
              </h3>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnAuthorizedAccess;
