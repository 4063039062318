import ctx from "../../plugins/axios";

/**
 * Get Specialties list.
 */
const getSpecialties = async (searchKeyword) =>
  await ctx.get(
    `specialty?lilly_specialty=true&search_keyword=${encodeURI(searchKeyword)}`
  );

export default getSpecialties;
