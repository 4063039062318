import {
  LdsButton,
  LdsModal,
  LdsScrollingCheckboxGroup,
  LdsSelect,
  LdsIcon,
} from "@elilillyco/ux-lds-react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_BRAND_BU_LIST } from "../../store/admin";
import { useImmer } from "use-immer";

const CreateEditBUModal = ({
  isEditModal,
  open,
  setModalOpen,
  BUDataObj,
  setBUDataObj,
}) => {
  const { productList, BUList } = useSelector(({ admin }) => admin);

  const [error, setError] = useImmer({ BU: false, brand: false });
  const [selectedBrands, setSelectedBrands] = useImmer([]);

  const dispatch = useDispatch();

  const BUOptions = JSON.parse(JSON.stringify(BUList));
  BUOptions.unshift({ value: "", label: "Select BU" });

  const handleBUChange = ({ value }) => {
    setBUDataObj((draft) => {
      draft.bu = value;
    });
    if (value) {
      setError((draft) => {
        draft.BU = false;
      });
    }
  };

  const handleBrandChange = (brands) => {
    setSelectedBrands(brands);
    if (brands.length) {
      setError((draft) => {
        draft.brand = false;
      });
    } else {
      setError((draft) => {
        draft.brand = true;
      });
    }
  };

  const handleSelectDeselectAll = () => {
    if (productList.length === selectedBrands.length) {
      handleBrandChange([]);
    } else {
      handleBrandChange(productList.map(({ value }) => value));
    }
  };

  const createEditBU = (e) => {
    e.preventDefault();

    let errorPresent = false;
    if (!BUDataObj.bu) {
      errorPresent = true;
      setError((draft) => {
        draft.BU = true;
      });
    }

    if (!isEditModal) {
      if (!selectedBrands.length) {
        errorPresent = true;
        setError((draft) => {
          draft.brand = true;
        });
      }
    }

    if (errorPresent) {
      return;
    }

    let BUPayload;
    if (isEditModal) {
      BUPayload = {
        product_bu: [BUDataObj],
      };
    } else {
      const data = productList
        .filter((product) => selectedBrands.includes(product.value))
        .map((option) => ({
          bu: BUDataObj.bu,
          prod_nm: option.value,
          uuid: option.uuid,
        }));
      BUPayload = {
        product_bu: data,
      };
    }

    dispatch(UPDATE_BRAND_BU_LIST(BUPayload));
  };

  return (
    <>
      (
      <LdsModal
        modalId="CreateEditBUModal"
        open={open}
        setModalOpen={setModalOpen}
      >
        <div>
          {isEditModal ? <h2>Edit BU Details</h2> : <h2>Add BU Details</h2>}
        </div>
        <form id="add-BU-form" onSubmit={createEditBU}>
          <div className="row" breakpoint="md">
            <div className="col col-12 col-md-5">
              <LdsSelect
                required={true}
                id="selectBU"
                value={(BUOptions.length > 1 && BUDataObj.bu) || ""}
                name="selectBU"
                options={BUOptions}
                placeholder="Select BU"
                label="BU"
                errorMessage="This field is required"
                error={error.BU}
                onChange={handleBUChange}
              />
            </div>
          </div>
          <div className="row" breakpoint="md">
            <div className="col col-12 col-md-5 mt-4">
              {isEditModal ? (
                <div>
                  <div>
                    <h4>Brand(s)</h4>
                  </div>
                  <div>
                    <span>{BUDataObj.prod_nm}</span>
                  </div>
                </div>
              ) : (
                <>
                  <LdsScrollingCheckboxGroup
                    label={
                      <>
                        Brand(s){" "}
                        <LdsButton linkButton onClick={handleSelectDeselectAll}>
                          {productList.length === selectedBrands.length
                            ? "Deselect"
                            : "Select"}{" "}
                          All
                        </LdsButton>
                      </>
                    }
                    labelId="brandSelect"
                    options={productList}
                    values={selectedBrands}
                    setValues={handleBrandChange}
                  />
                  {error.brand && (
                    <span
                      id="selectBU-select-error"
                      class="lds-form-validation-error"
                    >
                      <LdsIcon name="WarningCircleFill" inline /> This field is
                      required
                    </span>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div class="floatRight">
                <LdsButton
                  classes="button-icon-right blue-bdr-btn mrgn-rt cancelbtn"
                  onClick={() => setModalOpen(false)}
                >
                  Cancel
                </LdsButton>

                <LdsButton type="submit" classes="blue-btn">
                  {isEditModal ? "Save" : "Add"}
                </LdsButton>
              </div>
            </div>
          </div>
        </form>
      </LdsModal>
      )
    </>
  );
};

export default CreateEditBUModal;
