import ctx from "../../plugins/axios";

/**
 * Gets the Overview details.
 *
 * @returns {Promise<*>}
 */
const getDetails = async (selectedBrand) => {
    let queryStr;
    if (selectedBrand && (selectedBrand.user_role === 'spclty_hub_admin' || selectedBrand.user_role === 'spclty_hub_mlr_approver')) {
        queryStr = `/specialty_list?brand=${selectedBrand.brand}`;
    } else {
        queryStr = `/specialty_list?brand=${selectedBrand.brand}&created_by=${selectedBrand.created_by}&condition_type=OR`;
    }
    return await ctx.get(queryStr);
};

export default getDetails;