import './App.css';
import Routing from './routing/router';
import { defineLdsComponents } from '@elilillyco/ux-lds';
import '@elilillyco/ux-lds-react/src/css/index.css';
// import '@elilillyco/ux-lds/src/css/index.css';
import { useEffect } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './auth/authconfig';
import { store } from './store/store';
import { Provider } from 'react-redux';

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  useEffect(() => {
    /* Loads LDS web components into the window
      This is a dependency of ux-lds-react components
      This dependency may be removed as work on ux-lds-react progresses
    */
    defineLdsComponents();
  }, []);
  return (
    <>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <Routing></Routing>
        </Provider>
      </MsalProvider>
    </>
  );
}

export default App;
