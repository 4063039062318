import { LdsModal, LdsButton } from "@elilillyco/ux-lds-react";
import "./../../assets/scss/details/deleteSpecModal.scss";
const EditBulkUserStatus = ({
  isEditBulkStatusOpen,
  setisEditBulkStatusOpen,
  userstatus,
  bulkUpdateUserStatus,
}) => {
  return (
    <>
      <LdsModal
        modalId="edit-bulk-user-status-modal"
        className="text-center"
        open={isEditBulkStatusOpen}
        setModalOpen={setisEditBulkStatusOpen}
      >
        <div>
          <h2>
            Are you sure you want to{" "}
            {userstatus ? "bulk Activate" : "bulk Deactivate"} user.
          </h2>
        </div>
        <div className="savecl">
          <LdsButton
            iconPosition="before"
            className="button-icon-left blue-bdr-btn test"
            onClick={() => {
              setisEditBulkStatusOpen(false);
              bulkUpdateUserStatus(false);
            }}
          >
            No
          </LdsButton>
          <LdsButton
            iconPosition="before"
            className="button-icon-left  blue-btn ml-2"
            onClick={() => {
              setisEditBulkStatusOpen(false);
              bulkUpdateUserStatus(true);
            }}
          >
            Confirm Yes
          </LdsButton>
        </div>
      </LdsModal>
    </>
  );
};
export default EditBulkUserStatus;
