import ctx from "../../plugins/axios";

/**
 * Gets the Export Specaity List details.
 *
 * @returns {Promise<*>}
 */
const getExportSpecialtyList = async () => {
  return await ctx.get("/specialty_list/export");
};

export default getExportSpecialtyList;
