import ctx from "../../plugins/axios";

/**
 * Gets the Product List by Look-Up.
 *
 * @returns {Promise<*>}
 */
const getIndicationList = async (obj) => {
  return await ctx.get(
    `/indication?search_keyword=${obj.indication}&department_field=${obj.department}&grp_prps_cd=${obj.grpPrpsCd}`
  );
};

export default getIndicationList;
