import {
  LdsButton,
  LdsSearch,
  LdsTable,
  LdsIcon,
  LdsCard,
  useLdsModal,
} from "@elilillyco/ux-lds-react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useImmer } from "use-immer";

import { CreateEditBUModal, DeleteBUModal } from ".";

import { UPDATE_BRAND_BU_LIST } from "../../store/admin";

const BUTable = () => {
  const BUTableHeader = ["Brand", "BU"];

  const [delUUID, setDelUUID] = useState("");
  const [isEditModal, setIsEditModal] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [BUDataObj, setBUDataObj] = useImmer({});
  const [visibleBrandBUList, setVisibleBrandBUList] = useImmer([]);

  const { brandBUList } = useSelector(({ admin }) => admin);

  const dispatch = useDispatch();

  const { isModalOpen: isDelModalOpen, setIsModalOpen: setIsDelModalOpen } =
    useLdsModal(false);

  const {
    isModalOpen: isCreateEditModalOpen,
    setIsModalOpen: setIsCreateEditModalOpen,
  } = useLdsModal(false);

  const filterBUList = (value) => {
    value = value.toLowerCase();
    setVisibleBrandBUList((draft) => {
      draft.forEach((data) => {
        data.show =
          data.prod_nm.toLowerCase().includes(value) ||
          data.bu.toLowerCase().includes(value);
      });
    });
  };

  const showEditModal = (item) => {
    setIsEditModal(true);
    setBUDataObj(item);
  };

  const deleteBU = () => {
    const delPayload = {
      product_bu: [
        {
          prod_nm: "",
          uuid: delUUID,
          bu: "",
        },
      ],
    };
    dispatch(UPDATE_BRAND_BU_LIST(delPayload));
  };

  const showCreateModal = () => {
    setIsEditModal(false);
    setBUDataObj({});
    showCreateEditModal();
  };

  const showCreateEditModal = () => {
    setIsCreateEditModalOpen(true);
  };

  const showTable = () =>
    visibleBrandBUList.length > 0 &&
    visibleBrandBUList.some((element) => element.show);

  useEffect(() => {
    setVisibleBrandBUList(brandBUList);
  }, [brandBUList]);

  useEffect(() => {
    if (delUUID) {
      setIsDelModalOpen(true);
    }
  }, [delUUID]);

  useEffect(() => {
    if (!isDelModalOpen) {
      setDelUUID("");
    }
  }, [isDelModalOpen]);

  useEffect(() => {
    if (isEditModal) {
      if (Object.keys(BUDataObj).length) {
        showCreateEditModal();
      }
    }
  }, [isEditModal, BUDataObj]);

  useEffect(() => {
    if (!isCreateEditModalOpen) {
      setIsEditModal(false);
      setBUDataObj({});
    }
  }, [isCreateEditModalOpen]);

  return (
    <div>
      <form id="bu-form">
        <div className="row">
          <div className="col col-6">
            <LdsSearch
              value={searchText}
              name="search_keyword"
              onSubmit={filterBUList}
              onChange={(value) => setSearchText(value)}
            />
          </div>
          <div className="col col-6 ">
            <LdsButton
              icon="Plus"
              iconPosition="before"
              classes="button-icon-left blue-btn bu-btn"
              onClick={showCreateModal}
            >
              Edit BU
            </LdsButton>
          </div>
        </div>
        <LdsCard className="mt-5">
          <LdsCard.Body>
            {showTable() ? (
              <LdsTable id="bu-list-table" className="bu-list-table" flat>
                <thead>
                  <tr className="align-left">
                    {BUTableHeader.map((element, index) => (
                      <th scope="col" key={index}>
                        {element}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {visibleBrandBUList.map(
                    (item, index) =>
                      item.show && (
                        <tr key={index} className="align-left">
                          <td>{item.prod_nm}</td>
                          <td>{item.bu}</td>
                          <td>
                            <span
                              className="edit-btn"
                              onClick={() => showEditModal(item)}
                            >
                              <LdsIcon
                                name="PencilSimpleLineFill"
                                label="Edit"
                                decorative
                                inline
                              />
                            </span>
                          </td>
                          <td>
                            <span
                              className="delete-btn"
                              onClick={() => setDelUUID(item.uuid)}
                            >
                              <LdsIcon
                                name="TrashSimpleFill"
                                label="Delete"
                                decorative
                                inline
                              />
                            </span>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </LdsTable>
            ) : (
              <p> No Record Found </p>
            )}
          </LdsCard.Body>
        </LdsCard>
      </form>
      {isCreateEditModalOpen && (
        <CreateEditBUModal
          isEditModal={isEditModal}
          open={isCreateEditModalOpen}
          setModalOpen={setIsCreateEditModalOpen}
          BUDataObj={BUDataObj}
          setBUDataObj={setBUDataObj}
        />
      )}
      {isDelModalOpen && (
        <DeleteBUModal
          deleteBU={deleteBU}
          open={isDelModalOpen}
          setModalOpen={setIsDelModalOpen}
        />
      )}
    </div>
  );
};

export default BUTable;
