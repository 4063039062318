import { LdsButton, LdsModal } from "@elilillyco/ux-lds-react";

const CreateNewListModal = ({
  isModalOpen,
  setIsModalOpen,
  openCreateListModal,
}) => {
  /**
   * Checks if user is neither HCP SF Marketer nor Incentivized approver
   *
   * @returns
   */
  const checkSFMarketerRole = () =>
    !(
      sessionStorage.getItem("role") === "spclty_hub_hcp_sf_marketer" ||
      sessionStorage.getItem("role") === "spclty_hub_incentivized_approver"
    );

  return (
    <LdsModal
      open={isModalOpen}
      setModalOpen={setIsModalOpen}
      modalId="createNewListModal"
      modalSizeClass="col-12 col-sm-8 col-md-8 col-lg-8"
      className="text-center"
    >
      <div>
        <h2>Create a new list</h2>
        <p>
          Create a new list from scratch or use an existing list as an template
        </p>
      </div>
      <div className="col-12 col-md-12">
        <LdsButton
          classes="button-icon-right blue-bdr-btn outlined mx-4 mt-4"
          mode="alt"
          onClick={() => {
            openCreateListModal("existing");
          }}
        >
          Create from existing list
        </LdsButton>
        {checkSFMarketerRole() ? (
          <LdsButton
            classes="button-icon-right blue-btn mx-4 mt-4"
            mode="secondary"
            onClick={() => openCreateListModal("new")}
          >
            Create list from scratch
          </LdsButton>
        ) : (
          <></>
        )}
      </div>
    </LdsModal>
  );
};

export default CreateNewListModal;
