import { LdsLink } from "@elilillyco/ux-lds-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { UPDATE_ERROR_PAGE_DATA } from "../../store/error";

import "./../../assets/scss/error/error.scss";

const Error = () => {
  const { state } = useLocation();

  const dispatch = useDispatch();

  const status = state?.status;

  useEffect(() => {
    return () => {
      dispatch(UPDATE_ERROR_PAGE_DATA(0));
    };
  }, []);

  const render = () => {
    switch (status) {
      case 500:
        return (
          <h1>
            <h3>
              <u>Internal Server Error.</u>
              <br />I am sorry you are having issues.
            </h3>
            <h3>
              <u>Troubleshooting Tips:</u>
            </h3>
            <ol>
              <li className="error-li">
                If you are working remotely, please ensure you are connected to
                the Lilly network via BIG-IP Edge Client™
              </li>
              <li className="error-li">
                You can request access to the tool via My access to the
                Specialty Hub Tool via my Access{" "}
                <a href="https://myaccess.lilly.com/identityiq/accessRequest/accessRequest.jsf#/accessRequestSelf/add">
                  https://myaccess.lilly.com
                </a>
              </li>
              <li className="error-li">
                Under the manage access section, enter spclty_hub_ in the search
                bar to see a list of the Specialty Hub Tool user access
                entitlements. Please find the list of myAccess role entitlements
                for the Specialty Hub Tool and select the appropriate
                entitlement to place your my Access request
              </li>
              <li className="error-li">
                Select the role for which you need access:
                <br />
                Admin &#8594; spclty_hub_admin (Restricted Access only for
                Stakeholders and IT Services)
                <br />
                BIA Team &#8594; spclty_hub_bia_admin
                <br />
                HCP Marketer &#8594; spclty_hub_hcp_marketer
                <br />
                HCP SF Marketer &#8594; spclty_hub_hcp_sf_marketer
                <br />
                Incentivized Approver &#8594; spclty_hub_incentivized_approver
                <br />
                MLR Approver &#8594; spclty_hub_mlr_approver
                <br />
                Omnichannel Advisor &#8594; spclty_hub_omnichannel_advisor
                <br />
                Viewer &#8594; spclty_hub_viewer
              </li>
              <li className="error-li">
                Select your Lilly account and save it.
              </li>
              <li className="error-li">
                Now the request is sent for approval, and you will receive mail
                once it's approved.
              </li>
            </ol>
            <h4>
              Visit the confluence page for more details:
              <LdsLink href="https://lilly-confluence.atlassian.net/wiki/spaces/VDA/pages/922190825/Specialty+Hub+Tool">
                Specialty Hub Tool - IDS Data Automation (Awesomation) -
                Enterprise Confluence (atlassian.net)
              </LdsLink>
            </h4>
            <h4>
              Please Note: Access will be available within 4 hours of your
              access request being approved.
              <br />
              If you continue to have issues, please contact Sybil Sandlin:
              <a href="mailto: ssandlin@lilly.com">&nbsp;ssandlin@lilly.com</a>
            </h4>
          </h1>
        );
      case 401:
        return <h1>Not Authorized</h1>;
      default:
        return <h1>An error occurred</h1>;
    }
  };

  return (
    <div className="wrapper section">
      {render()}
      <LdsLink href="/">Home Page</LdsLink>
    </div>
  );
};

export default Error;
