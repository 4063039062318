import ctx from "../../plugins/axios";

/**
 * Gets the Product List by Brand.
 *
 * @returns {Promise<*>}
 */
const getExistingProductList = async (obj) => {
  return await ctx.get(
    `/specialty_list/brand?search_keyword=${obj.val}&list_status=APPROVED&department_field=${obj.department}`
  );
};

export default getExistingProductList;
