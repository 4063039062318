import { default as ctx } from "axios";
import data from "../data/env.config.json";

// Add a request interceptor
ctx.defaults.baseURL = sessionStorage.getItem('gateway') ? `https://${sessionStorage.getItem('gateway')}/brand-approved` : '';
ctx.interceptors.request.use(
  function (config) {
    config.headers["Authorization"] = `Bearer ${sessionStorage.getItem(
      "Token"
    )}`;

    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
ctx.interceptors.response.use(
  function (response) {
    // response.status = 403;
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    const response = error.response;
    if (response) {
      const { data, status } = response;

      // show error toast
      if (data) {
        import("./../utils/error/handleError").then((module) =>
          module.default(data)
        );
      }

      if (status) {
        const code = parseInt(status);
        if ([401, 403, 500, 504].includes(code)) {
          import("./../utils/error/redirectError").then((module) =>
            module.default(code)
          );
        }
      }
    }

    return Promise.reject(error);
  }
);

export default ctx;
