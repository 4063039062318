import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import exportExcelSheet from "../utils/overview/exportExcelSheet";
import {
  createSpecialtyList,
  getDetails,
  getExistingProductList,
  getExportSpecialtyList,
  getIndicationList,
  getIndicationListByBrand,
  getProductList,
  updateListStatus,
} from "../api/overview";

const initialState = {
  selectedBrand: "",
  current: [],
  historical: [],
  loading: true,
  allBrandMolecules: [],
  products: [],
  allIndications: [],
  indications: [],
  listUUID: "",
  undoDraftId: "",
};

const reducers = {
  SET_BRAND(state, { payload }) {
    state.selectedBrand = payload;
  },
  SET_PRODUCT_INDICATION_INITIAL_STATE(state) {
    state.indications = [];
    state.allIndications = [];
    state.products = [];
    state.allBrandMolecules = [];
  },
  SET_INDICATION_INITIAL_STATE(state) {
    state.indications = [];
    state.allIndications = [];
  },
  SET_LOADING(state, { payload }) {
    state.loading = payload;
  },
  RESET_PRODUCTS(state) {
    state.products = [];
  },
};

const FETCH_OVERVIEW = createAsyncThunk(
  "overview/fetchOverview",
  async (selectedBrand) => {
    const { data, status } = await getDetails(selectedBrand);
    return { data, status };
  }
);

const FETCH_EXPORT_SPECIALTY_LIST = createAsyncThunk(
  "overview/getExportSpecialtyList",
  async () => {
    const { data, status } = await getExportSpecialtyList();
    return { data, status };
  }
);

const FETCH_PRODUCT_LIST = createAsyncThunk(
  "overview/FETCH_PRODUCT_LIST",
  async (obj, { rejectWithValue }) => {
    const { data: productList, status } = await getProductList(obj);
    if (status === 200) {
      return productList.data || [];
    } else {
      return rejectWithValue(productList);
    }
  }
);

const FETCH_EXISTING_PRODUCT_LIST = createAsyncThunk(
  "overview/FETCH_EXISTING_PRODUCT_LIST",
  async (obj, { rejectWithValue }) => {
    const { data: productList, status } = await getExistingProductList(obj);
    if (status === 200) {
      return productList.data || [];
    } else {
      return rejectWithValue(productList);
    }
  }
);

const FETCH_INDICATION_LIST = createAsyncThunk(
  "overview/FETCH_INDICATION_LIST",
  async (obj, { rejectWithValue }) => {
    const { data: indicationList, status } = await getIndicationList(obj);
    if (status !== 200) {
      return rejectWithValue(indicationList);
    } else {
      const sorrtedArr = indicationList.data.sort((a, b) => {
        // Sort results by matching name with keyword position in name
        if (
          a.indication_description
            .toLowerCase()
            .indexOf(obj.indication.toLowerCase()) >
          b.indication_description
            .toLowerCase()
            .indexOf(obj.indication.toLowerCase())
        ) {
          return 1;
        }
        if (
          a.indication_description
            .toLowerCase()
            .indexOf(obj.indication.toLowerCase()) <
          b.indication_description
            .toLowerCase()
            .indexOf(obj.indication.toLowerCase())
        ) {
          return -1;
        }
        if (a.indication_description > b.indication_description) return 1;
        return -1;
      });
      return sorrtedArr || [];
    }
  }
);

const FETCH_INDICATION_LIST_BY_BRAND = createAsyncThunk(
  "overview/FETCH_INDICATION_LIST_BY_BRAND",
  async ({ brand, indication, department, grpPrpsCd }, { rejectWithValue }) => {
    const searchKeyWords = indication;
    const { data: indicationList, status } = await getIndicationListByBrand({
      brand: brand,
      department: department,
      search_keyword: searchKeyWords,
      grpPrpsCd,
    });
    if (status !== 200) {
      return rejectWithValue(indicationList);
    } else {
      const sorrtedArr = indicationList.data.sort((a, b) => {
        // Sort results by matching name with keyword position in name
        if (
          a.indication_description
            .toLowerCase()
            .indexOf(searchKeyWords.toLowerCase()) >
          b.indication_description
            .toLowerCase()
            .indexOf(searchKeyWords.toLowerCase())
        ) {
          return 1;
        }
        if (
          a.indication_description
            .toLowerCase()
            .indexOf(searchKeyWords.toLowerCase()) <
          b.indication_description
            .toLowerCase()
            .indexOf(searchKeyWords.toLowerCase())
        ) {
          return -1;
        }
        if (a.indication_description > b.indication_description) return 1;
        return -1;
      });
      return sorrtedArr || [];
    }
  }
);

const CREATE_SPECIALTY_LIST = createAsyncThunk(
  "overview/CREATE_SPECIALTY_LIST",
  async (cloneObj, { rejectWithValue }) => {
    const { data: createdList, status } = await createSpecialtyList(cloneObj);
    if (status !== 200) {
      return rejectWithValue(createdList);
    } else {
      return createdList.data.uuid || "";
    }
  }
);

const UPDATE_LIST_STATUS = createAsyncThunk(
  "overview/UPDATE_LIST_STATUS",
  async (deleteObj) => {
    const { data, status } = await updateListStatus(deleteObj);
    return { data, status, itemID: deleteObj.deleteDraftID };
  }
);

const UNDO_DELETE_LIST = createAsyncThunk(
  "overview/UNDO_DELETE_LIST",
  async (undoDeletedObj, { rejectWithValue }) => {
    const { data: deletedDraft, status } = await updateListStatus(
      undoDeletedObj
    );
    if (status !== 200) {
      return rejectWithValue(deletedDraft);
    }
    return {
      deletedDraft,
      status,
    };
  }
);

const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers,
  extraReducers(builder) {
    // FETCH_OVERVIEW
    builder.addCase(FETCH_OVERVIEW.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(FETCH_OVERVIEW.fulfilled, (state, { payload }) => {
      const { data, status } = payload;
      if (status === 200) {
        state.current = data.data.current_data;
        state.historical = data.data.history_data;
      }
      state.loading = false;
    });
    builder.addCase(FETCH_OVERVIEW.rejected, (state, { payload }) => {
      console.error("failed to fetch overview data", payload);
      state.current = [];
      state.historical = [];
      state.loading = false;
    });

    // FETCH_EXPORT_SPECIALTY_LIST
    builder.addCase(FETCH_EXPORT_SPECIALTY_LIST.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      FETCH_EXPORT_SPECIALTY_LIST.fulfilled,
      (state, { payload }) => {
        const { data, status } = payload;
        if (status === 200) {
          const sList = data.data;
          const sListDetails = [];
          for (let i = 0; i < sList.length; i++) {
            sListDetails.push({
              "Specialty List": sList[i].list_title || "",
              "List Quarter ": sList[i].list_quarter || "",
              Brand: sList[i].brand || "",
              "List Status": sList[i].list_status || "",
              Department: sList[i].department_field || "",
              Indication: sList[i].indication || "",
              Incentivized: sList[i].list_quarter ? "TRUE" : "FALSE",
              "Updated Date": sList[i].updated_date || "",
            });
          }

          exportExcelSheet(sListDetails, "SpecialtyList");
        }
        state.loading = false;
      }
    );
    builder.addCase(
      FETCH_EXPORT_SPECIALTY_LIST.rejected,
      (state, { payload }) => {
        console.error("failed to export specialty list", payload);
        state.loading = false;
      }
    );

    // FETCH_PRODUCT_LIST
    builder.addCase(FETCH_PRODUCT_LIST.fulfilled, (state, { payload }) => {
      state.allBrandMolecules = payload;
      state.products = payload.map((brand) => brand.prod_nm);
    });
    builder.addCase(FETCH_PRODUCT_LIST.rejected, (state, { payload }) => {
      console.error("failed to fetch product data", payload);
    });

    // FETCH_EXISTING_PRODUCT_LIST
    builder.addCase(
      FETCH_EXISTING_PRODUCT_LIST.fulfilled,
      (state, { payload }) => {
        state.allBrandMolecules = payload;
        state.products = payload.map((brand) => brand.prod_nm);
      }
    );
    builder.addCase(
      FETCH_EXISTING_PRODUCT_LIST.rejected,
      (state, { payload }) => {
        console.error("failed to fetch existing product data", payload);
      }
    );

    // FETCH_INDICATION_LIST
    builder.addCase(FETCH_INDICATION_LIST.fulfilled, (state, { payload }) => {
      state.allIndications = payload;
      state.indications = payload.map((a) => a.indication_description || []);
    });
    builder.addCase(FETCH_INDICATION_LIST.rejected, (state, { payload }) => {
      console.error("failed to fetch indication data", payload);
    });

    // FETCH_INDICATION_LIST_BY_BRAND
    builder.addCase(
      FETCH_INDICATION_LIST_BY_BRAND.fulfilled,
      (state, { payload }) => {
        state.allIndications = payload;
        state.indications = payload.map((a) => a.indication_description || []);
      }
    );
    builder.addCase(
      FETCH_INDICATION_LIST_BY_BRAND.rejected,
      (state, { payload }) => {
        console.error("failed to fetch indication data by brand", payload);
      }
    );

    // CREATE_SPECIALTY_LIST
    builder.addCase(CREATE_SPECIALTY_LIST.fulfilled, (state, { payload }) => {
      state.listUUID = payload;
    });
    builder.addCase(CREATE_SPECIALTY_LIST.rejected, (state, { payload }) => {
      console.error("failed to create new list", payload);
    });

    // UPDATE_LIST_STATUS
    builder.addCase(UPDATE_LIST_STATUS.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(UPDATE_LIST_STATUS.fulfilled, (state, { payload }) => {
      const { itemID, status } = payload;
      if (status === 200) {
        state.undoDraftId = itemID;
      }
      state.loading = false;
    });
    builder.addCase(UPDATE_LIST_STATUS.rejected, (state, { payload }) => {
      state.loading = false;
    });

    // UNDO_DELETE_LIST
    builder.addCase(UNDO_DELETE_LIST.fulfilled, (state, { payload }) => {
      state.undoDraftId = "";
    });
    builder.addCase(UNDO_DELETE_LIST.rejected, (state, { payload }) => {
      console.error("failed to undo delete list", payload);
    });
  },
});

export const {
  SET_BRAND,
  SET_PRODUCT_INDICATION_INITIAL_STATE,
  SET_INDICATION_INITIAL_STATE,
  SET_LOADING,
  RESET_PRODUCTS,
} = overviewSlice.actions;
export {
  FETCH_OVERVIEW,
  FETCH_EXPORT_SPECIALTY_LIST,
  FETCH_PRODUCT_LIST,
  FETCH_EXISTING_PRODUCT_LIST,
  FETCH_INDICATION_LIST,
  FETCH_INDICATION_LIST_BY_BRAND,
  CREATE_SPECIALTY_LIST,
  UPDATE_LIST_STATUS,
  UNDO_DELETE_LIST,
};

export default overviewSlice.reducer;
