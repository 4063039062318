import {
  LdsButton,
  LdsModal,
  LdsSearch,
  LdsSelect,
  LdsToast,
  useToastContext,
  LdsIcon,
} from "@elilillyco/ux-lds-react";

import "./../../assets/scss/overview/createListModal.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_SPECIALTY_LIST,
  FETCH_EXISTING_PRODUCT_LIST,
  FETCH_INDICATION_LIST,
  FETCH_INDICATION_LIST_BY_BRAND,
  FETCH_PRODUCT_LIST,
  RESET_PRODUCTS,
  SET_INDICATION_INITIAL_STATE,
  SET_PRODUCT_INDICATION_INITIAL_STATE,
} from "../../store/overview";
import { useNavigate } from "react-router-dom";
import infoToastConfig from "../shared/Toast";

import grdMapping from "./../../data/grpMapping.json";

const CreateListModal = ({
  isModalOpen,
  setIsModalOpen,
  modalType,
  navigateBack,
}) => {
  const options = [
    {
      label: "Select",
      value: "",
    },
    {
      label: "Q1",
      value: "Q1",
    },
    {
      label: "Q2",
      value: "Q2",
    },
    {
      label: "Q3",
      value: "Q3",
    },
    {
      label: "Q4",
      value: "Q4",
    },
  ];

  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [brandLoading, setBrandLoading] = useState(false);
  const [isBrandSelected, setIsBrandSelected] = useState(false);
  const [isBrand, setIsBrand] = useState(false);
  const [recordType, setRecordType] = useState("");
  const [selectedIndication, setSelectedIndication] = useState("");
  const [isIndicationSelected, setIsIndicationSelected] = useState(false);
  const [indicationLoading, setIndicationLoading] = useState(false);
  const [listQuarter, setListQuarter] = useState("");
  const [isQuarterSelected, setIsQuarterSelected] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const { uuid } = useSelector(({ user }) => user);
  const { products, allBrandMolecules, indications, allIndications } =
    useSelector(({ overview }) => overview);

  const dispatch = useDispatch();

  const { addToast } = useToastContext();

  const navigate = useNavigate();

  const checkSFRole = () => {
    if (
      sessionStorage.getItem("role") === "spclty_hub_hcp_sf_marketer" ||
      sessionStorage.getItem("role") === "spclty_hub_incentivized_approver"
    ) {
      return true;
    }
    return false;
  };

  const handleBrandMoleculesChange = (value) => {
    setSelectedBrand(value);
    setIsBrandSelected(
      allBrandMolecules.some((item) => item.prod_nm === value)
    );
    setIsBrand(
      allBrandMolecules.some(
        (item) =>
          item.prod_nm === value && item.record_type.toUpperCase() === "PRODUCT"
      )
    );
    const brandOrMolecule = allBrandMolecules.find(
      (item) => item.prod_nm === value
    );
    if (brandOrMolecule) {
      setRecordType(brandOrMolecule.record_type.toUpperCase());
    }

    if (!value) {
      setIsIndicationSelected(false);
      setSelectedIndication("");
      setRecordType("");
      dispatch(RESET_PRODUCTS());
    }
  };

  const handleBrandMoleculesInput = async (e) => {
    const value = e.target.value;

    setSelectedBrand(value);

    // fetching data only when some value is provided
    if (value) {
      setBrandLoading(true);

      if (modalType) {
        await dispatch(
          FETCH_EXISTING_PRODUCT_LIST({
            val: value,
            department: selectedDepartment,
          })
        );
        dispatch(SET_INDICATION_INITIAL_STATE());
      } else {
        await dispatch(
          FETCH_PRODUCT_LIST({
            val: value,
            department: selectedDepartment,
          })
        );
      }
      setBrandLoading(false);
    } else {
      setIsBrand(false);
      setIsBrandSelected(false);
      setRecordType("");
      dispatch(RESET_PRODUCTS());
    }

    setSelectedIndication("");
    setIsIndicationSelected(false);
  };

  const handleIndicationChange = (value) => {
    setSelectedIndication(value);
    setIsIndicationSelected(
      allIndications.some((item) => item.indication_description === value)
    );
  };

  const handleIndicationInput = async (e) => {
    const value = e.target.value;

    setSelectedIndication(value);

    const data = {
      indication: value,
      department: selectedDepartment,
      grpPrpsCd: grdMapping[recordType],
    };

    // fetching data only when some value is provided
    if (value) {
      setIndicationLoading(true);
      if (modalType) {
        data.brand = selectedBrand;
        await dispatch(FETCH_INDICATION_LIST_BY_BRAND(data));
      } else {
        await dispatch(FETCH_INDICATION_LIST(data));
      }
      setIndicationLoading(false);
    } else {
      setIsIndicationSelected(false);
    }
  };

  const handleQuarter = ({ value }) => {
    setListQuarter(value);
    setIsQuarterSelected(!!value);
  };
  const isBrandOrMolecule = () => {
    let component = <span>Select Brand or Molecule</span>;
    if (selectedDepartment === "MEDICAL") {
      component = <span>Select Molecule</span>;
    }
    return component;
  };

  const isIndicationOrDiseaseState = () => {
    let component = <span>Select Indication Or Disease State</span>;
    if (selectedDepartment === "MEDICAL") {
      component = <span>Select Disease State</span>;
    } else {
      if (isBrandSelected) {
        if (isBrand) {
          component = <span>Select Indication</span>;
        } else {
          component = <span>Select Disease State</span>;
        }
      }
    }
    return component;
  };

  const modalMessage = () => {
    let component = (
      <p>
        First search and select a brand OR molecule than select a disease state
        or an indication from the dropdown.
      </p>
    );
    if (selectedDepartment === "MEDICAL") {
      return (
        <p>
          First search and select a molecule than select a disease state from
          the dropdown.
        </p>
      );
    }
    return component;
  };

  const createNext = async () => {
    if (!isBrandSelected || !products.includes(selectedBrand)) {
      addToast(infoToastConfig("Please Select Brand or Molecule.", "error"));
      return;
    }

    if (!isIndicationSelected || !indications.includes(selectedIndication)) {
      addToast(
        infoToastConfig("Please Select Indication or Disease state.", "error")
      );
      return;
    }

    if (checkSFRole() && !isQuarterSelected) {
      addToast(infoToastConfig("Please Select Quarter.", "error"));
      return;
    }

    const index = allBrandMolecules.findIndex(
      (item) => item.prod_nm == selectedBrand
    );
    const prod_id = allBrandMolecules[index].prod_id;
    const indicationIdx = allIndications.findIndex(
      (itm) => itm.indication_description === selectedIndication
    );
    const indctn_id = allIndications[indicationIdx].indctn_id;

    const year = new Date().getFullYear();

    const cloneObj = {
      brand: selectedBrand,
      indication: selectedIndication,
      list_quarter: listQuarter,
      created_by: uuid,
      // Added Updated By
      updated_by: uuid,
      clone_type: modalType ? "FROM_EXISTING" : "FROM_SCRATCH",
      list_title: listQuarter
        ? `${year}-${listQuarter}-${selectedBrand}-${selectedIndication}`
        : `${year}-${selectedBrand}-${selectedIndication}`,
      prod_id: prod_id,
      indctn_id: indctn_id,
      department_field: selectedDepartment,
    };
    const { meta, payload: listUUID } = await dispatch(
      CREATE_SPECIALTY_LIST(cloneObj)
    );
    if (meta.requestStatus === "fulfilled") {
      if (listUUID) {
        addToast(
          infoToastConfig(
            "Your changes have been saved successfully.",
            "success"
          )
        );
        navigate(`/details/${listUUID}`);
      }
    }
  };

  useEffect(() => {
    // the button disabled state depends on quarter when user is hcp sf marketer or incentivized approver and brand is selected
    const quarterSelected = checkSFRole() ? isQuarterSelected : true;
    // enable the next button once brand/molecule, indication and quarter is selected
    setIsNextButtonDisabled(
      !(isBrandSelected && isIndicationSelected && quarterSelected)
    );
  }, [isBrandSelected, isIndicationSelected, isQuarterSelected]);

  useEffect(() => {
    if (!isModalOpen) {
      setIsNextButtonDisabled(true);
      setIsBrandSelected(false);
      setSelectedBrand("");
      setIsBrand(false);
      setRecordType("");
      setSelectedIndication("");
      setIsIndicationSelected(false);
      setListQuarter("");
      setIsQuarterSelected(false);
      setSelectedDepartment("");
      dispatch(SET_PRODUCT_INDICATION_INITIAL_STATE());
    }
  }, [isModalOpen]);

  return (
    <LdsModal
      open={isModalOpen}
      setModalOpen={setIsModalOpen}
      modalId="createListModal"
      closeBtnAriaLabel="Close"
      className="text-center"
    >
      <LdsButton
        clearDefaultClasses
        aria-label="Back"
        classes="lds-button-clear-style lds-modal-close-btn icon back-button"
        onClick={navigateBack}
      >
        <LdsIcon name="ArrowLeft" label="Back" />
      </LdsButton>
      <div>
        {modalType ? (
          <h2>Create list from existing list.</h2>
        ) : (
          <h2>Create list from scratch.</h2>
        )}
        {modalMessage()}
      </div>
      <div className="row">
        <div className="col-3">
          <LdsSelect
            id="departmentSelect"
            label="Department"
            name="departmentSelect"
            value={selectedDepartment}
            options={[
              {
                value: "",
                label: "Select",
              },
              {
                value: "RETAIL",
                label: "Commercial",
              },
              {
                value: "MEDICAL",
                label: "Medical",
              },
            ]}
            onChange={(option) => {
              setSelectedDepartment(option.value);
              setSelectedBrand("");
              setIsBrandSelected(false);
              setSelectedIndication("");
              setIsIndicationSelected(false);
              setIsBrand(false);
              setRecordType("");
            }}
          />
        </div>

        <div
          className={`col col-12 ${checkSFRole() ? "col-md-4" : " col-md-4"}`}
        >
          {isBrandOrMolecule()}
          <sup>*</sup>
          <LdsSearch
            id="selectedBrand"
            name="q-type-ahead-brand"
            isTypeAhead={true}
            value={selectedBrand}
            onChange={handleBrandMoleculesChange}
            onInput={handleBrandMoleculesInput}
            searchSuggestions={products}
            isLoading={brandLoading}
            disabled={selectedDepartment === ""}
          />
        </div>
        <div className={`col col-12${checkSFRole() ? "col-md-5" : "col-md-5"}`}>
          {isIndicationOrDiseaseState()}
          <sup>*</sup>
          {/* disabling indication search until brand is selected in case of existing list creation*/}
          <LdsSearch
            id="SelectIndication"
            name="q-type-ahead-indication"
            isTypeAhead={true}
            value={selectedIndication}
            searchSuggestions={indications}
            onChange={handleIndicationChange}
            onInput={handleIndicationInput}
            isLoading={indicationLoading}
            disabled={!isBrandSelected || selectedDepartment === ""}
          />
        </div>
        {checkSFRole() ? (
          <div
            className={`col col-12 ${checkSFRole() ? "col-md-2" : "col-md-6"}`}
          >
            Select Quarter<sup>*</sup>
            <LdsSelect
              id="list_quarter"
              name="list_quarter"
              options={options}
              required={true}
              value={listQuarter}
              onChange={handleQuarter}
            ></LdsSelect>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="row next-btn-blk">
        <div className="col col-12 col-md">
          <LdsButton
            classes={`${
              isNextButtonDisabled || selectedDepartment === ""
                ? "outline secondary"
                : "blue-btn"
            }`}
            disabled={isNextButtonDisabled || selectedDepartment === ""}
            onClick={createNext}
          >
            Next
          </LdsButton>
        </div>
      </div>
      <LdsToast />
    </LdsModal>
  );
};

export default CreateListModal;
