import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  LdsButton,
  LdsModal,
  useLdsModal,
  LdsLink,
  LdsIcon,
  useToastContext,
} from "@elilillyco/ux-lds-react";
import exportExcelSheet from "../../utils/overview/exportExcelSheet";
import infoToastConfig from "../../components/shared/Toast";
import "../../assets/scss/superadmin/index.scss";
import { POST_UPLOAD } from "../../store/admin";

const Import = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user.user);
  const { uploaded, importMsg } = useSelector(({ admin }) => admin);
  const { toasts, addToast, removeToast, clearAllToasts } = useToastContext();
  const [spcltylistfile, setspcltylistfile] = useState(null);
  const [loading, setloading] = useState(false);
  const exportcsv = async (fileDetail) => {
    if (fileDetail === "BIA") {
      exportExcelSheet(
        [
          {
            business_unit: "PR SALES DIVISION",
            sales_force_code: "USH9",
            sales_force_name: "IMMUNOLOGY PR BIOMED (USH9)",
            brand: "OLUMIANT RA",
            indication: "Non-small Cell Lung Cancer",
            ims_market_id: 82,
            ims_market_description: "Rheumatoid Arthritis",
            incentivized: "Incentivized",
            specialty_code: "HPD",
            specialty_description: "Hospice & Palliative Med (Radiology)",
            specialty_code_and_description:
              "Hospice & Palliative Med (Radiology)-HPD",
            no_of_spclties_to_check: 1,
            ly_mtm_market_id: 211990,
          },
        ],
        "TTP CSV Business Rules"
      );
    } else {
      exportExcelSheet(
        [
          {
            "Brand/Product Group Name|Indication|I or E|Specialty Description|Specialty Code|Specialty Group|Specialty Group Code|BU|Department Field":
              "ALIMTA|Non-small Cell Lung Cancer|I|Abdominal Radiology|AR|Radiology|R|OBU|RETAIL",
          },
        ],
        "BIA mlr brand specialties"
      );
    }
  };
  const uploadFile = (event) => {
    setspcltylistfile(event.target.files[0]);
  };
  const submitFile = async () => {
    if (!spcltylistfile) {
      addToast(infoToastConfig("Please Select File to Upload", "error"));
      return;
    }
    const file = spcltylistfile;
    setloading(true);
    const fileStream = await toBase64(file);
    dispatch(
      POST_UPLOAD({
        file_name: file.name,
        file_content: fileStream,
        created_by: user.uuid,
      })
    );
    // await wait(dispatch(POST_UPLOAD), 200, {
    //   file_name: file.name,
    //   file_content: fileStream,
    //   created_by: this.$store.getters["user/userId"],
    // });
    await wait();
    if (uploaded) {
      addToast(infoToastConfig("Lists added successfully.", "success"));
      setloading(false);
    }
  };
  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const wait = async (delay = 1500) => {
    const endTime = Date.now() + delay;

    const remainingTime = endTime - Date.now();
    if (remainingTime > 0)
      await new Promise((resolve) => setTimeout(resolve, remainingTime));
  };
  return (
    <>
      <section className="section">
        <div className="wrapper overview-page">
          <div className="mt-3">
            <div className="row">
              <div className="col-12 mt-4 pl-4">
                <h2>Import Specialties List</h2>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-3">
                <p>Download Sample Files:</p>
              </div>
              <div className="col-3">
                <LdsButton
                  classes="blue-btn compact"
                  onClick={() => exportcsv("BIA")}
                >
                  BIA mlr brand specialties
                </LdsButton>
              </div>
              <div className="col-3">
                <LdsButton
                  classes="blue-btn compact"
                  onClick={() => exportcsv("TTP")}
                >
                  TTP CSV Business Rules
                </LdsButton>
              </div>
            </div>
            <div className="row mt-4">
              <div>
                {/* <input ref="file" type="file" accept=".xls,.xlsx,.csv," /> */}
                <input
                  type="file"
                  accept=".xls,.xlsx,.csv,"
                  onChange={uploadFile}
                ></input>
                <LdsButton classes="blue-btn ml-2" onClick={submitFile}>
                  Upload!
                </LdsButton>
              </div>
            </div>
            <div className="row mt-4">
              {Array.isArray(importMsg) &&
                importMsg.map((item, index) => <h6>{item}</h6>)}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Import;
