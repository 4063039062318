import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CLoader from "../../components/shared/CLoader";
import { BUTable } from "../../components/admin";

import { FETCH_BU_DATA } from "../../store/admin";

import "./../../assets/scss/admin/BU.scss";

const Bu = () => {
  const { BULoading } = useSelector(({ admin }) => admin);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FETCH_BU_DATA());
  }, []);

  return (
    <section className="section">
      <div className="wrapper">
        <div className="mt-3">
          <div className="row">
            <div className="col-12 mt-4 pl-4">
              <h2>BU Screen</h2>
            </div>
          </div>
          <div className="mt-3">
            {BULoading ? (
              <CLoader size={90} />
            ) : (
              <BUTable
                BUBrandData="BrandBuList"
                brandListData="productList"
                buDropdown="BuList"
                saveBUListForm="saveBUListForm"
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bu;
